const state = {
    selectedAttributes: null,
    propertyDialog: false,
    filterAttributes: null,
  };
  
  const mutations = {
    SET_SELECTED_ATTRIBUTES(state, creteria) {
      state.selectedAttributes = creteria;
    },

    SET_FILTER_ATTRIBUTES(state, attribute) {
      state.filterAttributes = attribute;
    },

    SET_PROPERTY_DIALOG(state, value) {
      state.propertyDialog = value;
    },
  };
  
  const actions = {
    setSelectedAttributes({ commit }, creteria) {
      commit('SET_SELECTED_ATTRIBUTES', creteria);
    },

    setFilterAttributes({ commit }, attributes) {
      commit('SET_FILTER_ATTRIBUTES', attributes);
    },

    setPropertyDialog({ commit }, value) {
      commit('SET_PROPERTY_DIALOG', value);
    },
  };
  
  const getters = {
    getSelectedAttributes: (state) => state.selectedAttributes,
    getFilterAttributes: (state) => state.filterAttributes,
    getPropertyDialog: (state) => state.propertyDialog,
  };
  
  export const filter = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  