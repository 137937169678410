<template>
  <div class="ma-1">
    <v-app-bar :elevation="1">
      <v-icon
        color="error"
        class="ml-2"
        icon="mdi-chevron-left"
        size="large"
        @click="logoRoute"
      ></v-icon>
      <v-img
        class="d-flex justify-center"
        :src="require('@/assets/suuq.logo.svg')"
        alt="SUUQ Logo"
        @click="logoRoute"
      />
    </v-app-bar>
    <v-card
      elevation="0"
      :loading="loading"
      :class="$vuetify.display.mobile ? '' : 'mx-auto'"
      :width="$vuetify.display.mobile ? '' : '1100'"
    >
      <v-card-title class="mb-1">
        <div>
          My Ads
          <div class="text-caption text-grey mt-n1">All my listings</div>
        </div>
      </v-card-title>
      <template v-slot:loader="{ isActive }">
        <v-progress-linear
          :active="isActive"
          color="error"
          height="5"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-for="listing in listings" :key="listing.id">
            <v-card class="mb-2" elevation="0" @click="selectItem(listing)">
              <v-row>
                <v-col cols="12" md="4">
                  <v-card class="ma-2" elevation="0" max-width="900">
                    <v-carousel class="pa-2" show-arrows="hover" cycle height="250">
                      <v-carousel-item
                        v-for="(image, i) in listing.files"
                        :key="i"
                        rounded="lg"
                        cover
                        :src="`data:${image?.mime_type};base64,${image.base64_data}`"
                      >
                        <div class="ma-2">
                          <span class="d-flex justify-start">
                            <v-btn
                              v-if="listing.verified"
                              color="white"
                              size="small"
                              prepend-icon="mdi-check-circle-outline"
                              class="text-capitalize"
                            >
                              <span class="ml-n1">Verified</span>
                            </v-btn>
                          </span>
                          <span :class="listing.verified ? 'd-flex justify-end mt-n6 mr-2':'d-flex justify-end mr-2'">
                            <v-icon
                              color="error"
                              icon="mdi-delete"
                              size="large"
                              @click.stop="deleteImage(image)"
                            ></v-icon>
                          </span>
                        </div>
                      </v-carousel-item>
                    </v-carousel>
                  </v-card>
                </v-col>
                <v-col cols="12" md="8">
                  <v-card elevation="0" class="mt-n2">
                    <template v-slot:prepend>
                      <v-card-title>{{ listing.title }} </v-card-title>
                    </template>
                    <template v-slot:append>
                      <!-- <v-btn
                        class="me-2"
                        color="black"
                        size="x-small"
                        variant="outlined"
                        icon="mdi-pencil"
                        @click="setSelectedItem(listing)"
                      ></v-btn> -->

                      <v-btn
                        class=""
                        color="error"
                        size="x-small"
                        icon="mdi-delete"
                        variant="outlined"
                        @click="deleteItem(listing)"
                      ></v-btn>
                    </template>
                    <v-card-text>
                      <v-row>
                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.price"
                          ><span class="text-grey font-weight-bold">
                            Price(USD)</span
                          ></v-col
                        >
                        <v-col class="mb-n3" cols="6" v-if="listing.price"
                          ><span class="ml-3">{{
                            convertToCurrency(listing.price)
                          }}</span></v-col
                        >
                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.category.name"
                          ><span class="text-grey font-weight-bold"
                            >Category</span
                          ></v-col
                        >
                        <v-col
                          class="mb-n3"
                          cols="6"
                          v-if="listing.category.name"
                          ><span class="ml-3">{{
                            listing.category.name
                          }}</span></v-col
                        >

                        <v-col
                          class="mb-n5"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.amount"
                          ><span class="text-grey font-weight-bold">
                            Ad Cost(USD)</span
                          ></v-col
                        >
                        <v-col class="mb-n5" cols="6" v-if="listing.amount"
                          ><span class="ml-3">{{ listing.amount }}</span></v-col
                        >

                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.status"
                          ><span class="text-grey font-weight-bold"
                            >Status</span
                          ></v-col
                        >
                        <v-col class="mb-n3" cols="6" v-if="listing.status"
                          ><span class="ml-3">{{
                            getStatusTitle(listing.status)
                          }}</span></v-col
                        >

                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.ApprovedDate"
                          ><span class="text-grey font-weight-bold"
                            >Approved Date</span
                          ></v-col
                        >
                        <v-col
                          class="mb-n3"
                          cols="6"
                          v-if="listing.ApprovedDate"
                          ><span class="ml-3">{{
                            formatDate(listing.ApprovedDate)
                          }}</span></v-col
                        >

                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.expires_at"
                          ><span class="text-grey font-weight-bold">
                            Expiry Date
                          </span></v-col
                        >

                        <v-col class="mb-n3" cols="6" v-if="listing.expires_at"
                          ><span class="ml-3">{{
                            formatDate(listing.expires_at)
                          }}</span></v-col
                        >

                        <v-col
                          class="mb-n3"
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.rejectReason"
                          ><span class="text-grey font-weight-bold">
                            Reject Reason</span
                          ></v-col
                        >

                        <v-col
                          class="mb-n3"
                          cols="6"
                          v-if="listing.rejectReason"
                          ><span class="ml-3">{{
                            listing.rejectReason
                          }}</span></v-col
                        >
                        <v-col
                          :cols="$vuetify.display.mobile ? '6' : '4'"
                          v-if="listing.city.title && listing.city.country.name"
                          ><span class="text-grey font-weight-bold">
                            Location</span
                          ></v-col
                        >
                        <v-col
                          cols="6"
                          v-if="listing.city.title && listing.city.country.name"
                          ><span class="ml-3">{{
                            listing.city.title +
                            "," +
                            " " +
                            listing.city.country.name
                          }}</span></v-col
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import ListingService from "../services/listing.service.js";
import FileService from "../services/file.service.js";
import CategoriesService from "../services/category.services.js";
import CitiesService from "../services/cities.services.js";
import moment, { now } from "moment";
import TransactionsService from "../services/transaction.service.js";
import usersService from "../services/user.service.js";

import Classified from "../components/ViewList/ClassifiedList.vue";
import PropertyList from "../components/ViewList/PropertyList.vue";
import CommunityList from "../components/ViewList/CommunityList.vue";
import JobList from "../components/ViewList/JobList.vue";
import MotorList from "../components/ViewList/MotorList.vue";
import FurnitureGarden from "../components/ViewList/FurnitureGarden.vue";
import MobileTablet from "../components/ViewList/MobileTablet.vue";

export default {
  components: {
    Classified,
    PropertyList,
    CommunityList,
    JobList,
    MotorList,
    FurnitureGarden,
    MobileTablet,
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    ListDialog: false,
    selectedItem: {},
    isValid: false,
    loading: true,
    rules: {
      name: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a name.";
        },
      ],
      slug: [
        (value) => {
          if (value?.length >= 1) return true;
          return "You must enter a item.";
        },
      ],
      date: [
        (v) => !!v || "Expiry date is required",
        (v) => (v && moment(v, "MM-DD-YYYY", true).isValid()) || "Invalid date",
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Title", value: "title", key: "title" },
      { title: "Price", value: "price", key: "price" },
      {
        title: "Transaction Amount",
        value: "transactionId.amount",
        key: "transactionId.amount",
      },
      { title: "Category", value: "category.name", key: "category.name" },

      { title: "Location(City)", value: "city.title", key: "city.title" },
      { title: "Status", value: "status", key: "status" },
      { title: "Expiry Date", value: "expires_at", key: "expires_at" },
      { title: "Approved Date", value: "ApprovedDate", key: "ApprovedDate" },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],

    editedIndex: -1,
    listings: [],
    categories: [],
    transactions: [],
    users: [],
    cities: [],

    statuses: [
      { id: 1, title: "Approved" },
      { id: 2, title: "Pending" },
      { id: 3, title: "Rejected" },
    ],

    fetured: [
      { id: 1, title: "Featured" },
      { id: 0, title: "Not Featured" },
    ],
    newFiles: [],
    editedItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      ApprovedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
    },

    defaultItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      approvedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Listing" : "Edit Listing";
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    formattedExpiryDate: {
      get() {
        return this.editedItem.expiryDate
          ? moment(this.editedItem.expiryDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.expiryDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },

    formattedApprovedDate: {
      get() {
        return this.editedItem.ApprovedDate
          ? moment(this.editedItem.ApprovedDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.ApprovedDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },

    formattedStartDate: {
      get() {
        return this.editedItem.startDate
          ? moment(this.editedItem.startDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.startDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getCategories();
    this.gettransactions();
    this.getUsers();
    this.getCities();
    if (this.loggedInUser) {
      this.getlistings();
    } else {
      this.handleLogout();
    }
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },

    getCategoryComponent(slug) {
      switch (slug) {
        case "property":
          return "PropertyList";
        case "motors":
          return "MotorList";
        case "community":
          return "CommunityList";
        case "jobs":
          return "JobList";
        case "classified":
          return "Classified";
        case "funiture&garden":
          return "FurnitureGarden";
        case "mobile&tables":
          return "MobileTablet";
        default:
          return null;
      }
    },

    getlistings() {
      return ListingService.getUserListings(this.loggedInUser._id).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getCategories() {
      return CategoriesService.onlyCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.categories = response.data.data.map((category) => ({
              ...category,
              title: category.name,
            }));
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    gettransactions() {
      return TransactionsService.index().then(
        (response) => {
          if (response.status == 200) {
            // this.transactions = response.data.data;
            this.transactions = response.data.data.map((transaction) => ({
              ...transaction,
              title: transaction.transactionReference, // Set fullName to title
            }));
            this.loading = false;
          } else {
            this.transactions = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.transactions = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    addFiles() {
      console.log("Selected files:", this.newFiles);
      this.editedItem.files = [...this.newFiles];
      console.log("New files:", this.editedItem.files);
    },

    getStatusTitle(status) {
      const statusMap = {
        1: "Approved",
        2: "Pending",
        3: "Rejected",
      };
      return statusMap[status] || "Unknown";
    },

    getUsers() {
      return usersService.index().then(
        (response) => {
          if (response.status == 200) {
            // this.users = response.data.data;
            this.users = response.data.data.map((user) => ({
              ...user,
              title: user.fullName, // Set fullName to title
            }));
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.users = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCities() {
      return CitiesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getStatusTitle(statusId) {
      const status = this.statuses.find((status) => status.id === statusId);
      return status ? status.title : "Unknown";
    },

    selectItem(item) {
      this.$store.dispatch("category/setSelectedItem", item);
      this.$router.push("/product");
    },

    setSelectedItem(item) {
      console.log("ittt", item);
      this.selectedItem = { ...item };
      this.ListDialog = true;
    },

    editItem(item) {
      console.log("Selected item:", item);

      // Set the edited index
      this.editedIndex = this.listings.indexOf(item);

      // Clone the selected item
      this.editedItem = {
        id: item.id,
        title: item.title || "",
        price: item.price || 0,
        categoryId: item.category ? item.category : "", // Extract categoryId from nested category object
        transactionId: item.transactionId || "",
        userId: item.user ? item.user : "", // Extract userId from nested user object (if available)
        cityId: item.city ? item.city : "", // Extract cityId from nested city object
        isFeatured: item.isFeatured || "",
        views: item.views || "",
        tags: item.tags || "",
        evc: item.evc || "",
        currency: item.currency || "",
        contactInformation: item.contactInformation || "",
        expires_at: item.expires_at ? new Date(item.expires_at) : new Date(),
        status: item.status || "",
        approvedDate: item.approvedDate
          ? this.formatDate(item.ApprovedDate)
          : this.formatDate(moment()),
        rejectReason: item.rejectReason || "",
        description: item.description || "",
        condition: item.condition || "",
        files: item.files || [], // If files array is present, assign it, otherwise empty array
      };

      // Ensure ApprovedDate is formatted correctly
      if (!this.editedItem.ApprovedDate) {
        this.editedItem.ApprovedDate = this.formatDate(moment());
      }

      // Open the dialog for editing
      this.dialog = true;
    },

    deleteItem(item) {
      console.log("Seer me her", item);
      this.editedIndex = this.listings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.deleteItemConfirm(this.editedItem);
    },

    deleteItemConfirm() {
      ListingService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.listings.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    deleteImage(file) {
      return FileService.delete(file).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    convertToCurrency(value) {
      let number = value;

      if (typeof value === "string") {
        number = parseFloat(value.replace(/[^0-9.-]+/g, ""));
      }

      if (!isNaN(number)) {
        return number.toLocaleString("en-US");
      } else {
        return "Invalid number";
      }
    },

    save() {
      this.editedItem.name = this.editedItem.title;
      console.log("we are here", this.editedItem);
      if (this.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.listings[this.editedIndex], this.editedItem);
          ListingService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                this.loading = false;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          ListingService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.listings.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>