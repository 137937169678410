<template>
  <div class="mx-3">
    <v-row v-if="Listing" class="mx-2">
      <v-col cols="12">
        <v-row
          class="ma-2"
          :class="this.$vuetify.display.mobile ? 'mx-n16' : ''"
        >
          <v-col cols="12" md="12" @click="showAllImages">
            <v-row>
              <v-col
                cols="12"
                md="6"
                v-if="
                  !this.$vuetify.display.mobile || Listing.files.length <= 2
                "
              >
                <div
                  :class="
                    this.$vuetify.display.mobile
                      ? 'rounded-lg mx-n9'
                      : 'rounded-lg mr-n7'
                  "
                >
                  <v-img
                    height="404"
                    :src="`data:${Listing.files[0]?.mime_type};base64,${Listing.files[0]?.base64_data}`"
                    cover
                    class="rounded-lg"
                    aspect-ratio="16/9"
                  >
                    <div class="ma-2" v-if="Listing.verified">
                      <v-btn
                        color="white"
                        size="x-small"
                        prepend-icon="mdi-check-circle-outline"
                        class="text-capitalize"
                      >
                        <span class="ml-n1">Verified</span>
                      </v-btn>
                    </div>
                  </v-img>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <v-col cols="12" v-if="!this.$vuetify.display.mobile">
                  <div
                    :class="
                      this.$vuetify.display.mobile
                        ? 'rounded-lg mx-n9 mt-n3'
                        : 'rounded-lg mt-n3'
                    "
                  >
                    <v-img
                      height="200"
                      :src="`data:${Listing.files[1]?.mime_type};base64,${Listing.files[1]?.base64_data}`"
                      cover
                      class="rounded-lg"
                      aspect-ratio="16/9"
                    >
                    </v-img>
                  </div>
                </v-col>
                <v-col cols="12" v-if="Listing && Listing.files.length > 2">
                  <div
                    :class="
                      this.$vuetify.display.mobile
                        ? 'rounded-lg mx-n9 mt-n3'
                        : 'rounded-lg mt-n5'
                    "
                  >
                    <v-img
                      height="200"
                      :src="`data:${Listing.files[2]?.mime_type};base64,${Listing.files[2]?.base64_data}`"
                      cover
                      class="rounded-lg"
                      aspect-ratio="16/9"
                    >
                      <div
                        class="ma-2"
                        v-if="Listing && Listing.files.length > 2"
                      >
                        <v-icon icon="mdi-image-area"></v-icon>
                        <span class="text-caption mt-1">{{
                          Listing.files.length
                        }}</span>
                      </div>
                      <div
                        class="d-flex justify-end mt-n8"
                        v-if="
                          this.$vuetify.display.mobile &&
                          Listing.files.length > 2
                        "
                      >
                        <v-btn
                          color="white"
                          size="x-small"
                          prepend-icon="mdi-check-circle-outline"
                          class="text-capitalize"
                        >
                          <span class="ml-n1">Verified</span>
                        </v-btn>
                      </div>
                    </v-img>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <!-- IMAGES DIALOG -->
          <v-dialog
            v-model="AllImages"
            transition="dialog-bottom-transition"
            fullscreen
          >
            <v-card>
              <v-toolbar>
                <v-icon
                  class="ml-2"
                  size="small"
                  color="error"
                  icon="mdi-arrow-left"
                  @click="AllImages = false"
                ></v-icon>

                <v-spacer></v-spacer>

                <v-btn
                  color="success"
                  variant="text"
                  prepend-icon="mdi-heart-outline"
                  class=""
                  @click="close"
                >
                  Favorite
                </v-btn>
              </v-toolbar>
              <v-card-text class="ma-4">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(image, index) in Listing.files"
                    :key="index"
                  >
                    <div
                      :class="
                        this.$vuetify.display.mobile
                          ? 'rounded-lg mx-n9'
                          : 'rounded-lg'
                      "
                    >
                      <v-img
                        height="350"
                        :src="`data:${image?.mime_type};base64,${image?.base64_data}`"
                        cover
                        class="rounded-lg"
                        aspect-ratio="16/9"
                      >
                      </v-img>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-toolbar
                v-if="!this.$vuetify.display.mobile"
                :elevation="1"
                color="white"
                class="position-sticky bottom-0 justify-center align-center rounded-lg mx-auto ma-2 pa-2"
                style="width: 44%"
              >
                <v-row>
                  <v-col cols="12" md="4">
                    <v-row>
                      <v-col cols="6">
                        <v-img
                          height="80"
                          src="@/assets/profile.png"
                          class="rounded-lg"
                        ></v-img>
                      </v-col>
                      <v-col cols="6">
                        <div class="mt-8 ml-n3">
                          <span class="font-weight-black">
                            {{ Listing.user?.fullName }}</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="8" class="m-7">
                    <v-btn
                      color="primary"
                      variant="tonal"
                      prepend-icon="mdi-email"
                      class="ml-4 mt-6"
                      @click="emailDialogOn"
                    >
                      Email
                    </v-btn>
                    <v-btn
                      color="error"
                      variant="tonal"
                      prepend-icon="mdi-phone"
                      class="ml-2 mt-6"
                      @click="callDialog = true"
                    >
                      Call
                    </v-btn>
                    <v-btn
                      color="success"
                      variant="tonal"
                      prepend-icon="mdi-whatsapp"
                      class="ml-2 mt-6"
                      :href="whatsappUrl"
                      target="_blank"
                    >
                      Whatsapp
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </v-card>
          </v-dialog>
          <!-- END -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="8" class="ml-n5">
                <v-card
                  :class="this.$vuetify.display.mobile ? 'mr-n9' : ''"
                  elevation="0"
                >
                  <template v-slot:title>
                    <div class="d-flex">
                      <span class="font-weight-black">{{
                        convertToCurrency(selectedItem.price)
                      }}</span>
                    </div>
                    <div class="text-caption">
                      <span v-if="Listing.activeRelation.bedrooms > 0">
                        <v-icon class="ml-n1" icon="mdi-bed-queen"></v-icon>
                        <span class="text-black ml-1">{{
                          Listing.activeRelation.bedrooms + " " + "Bed(s)"
                        }}</span>
                      </span>

                      <span v-if="Listing.activeRelation.bathrooms > 0">
                        <v-icon
                          :class="
                            Listing.activeRelation.bedrooms ? 'ml-6' : 'ml-n1'
                          "
                          icon="mdi-shower"
                        ></v-icon>
                        <span class="text-black ml-1">{{
                          Listing.activeRelation.bathrooms + " " + "Bathroom(s)"
                        }}</span>
                      </span>
                      <span v-if="Listing.activeRelation.size > 0">
                        <v-icon
                          :class="
                            Listing.activeRelation.bedrooms &&
                            Listing.activeRelation.bedrooms
                              ? 'ml-6'
                              : 'ml-n1'
                          "
                          icon="mdi-ruler-square"
                        ></v-icon>
                        <span class="text-black ml-1">{{
                          Listing.activeRelation.size + " " + "sqft"
                        }}</span>
                      </span>
                    </div>
                  </template>

                  <template v-slot:append>
                    <v-row v-if="!this.$vuetify.display.mobile">
                      <v-col cols="12" md="6">
                        <v-btn
                          color="blue"
                          variant="text"
                          prepend-icon="mdi-message"
                          :class="this.$vuetify.display.mobile ? '' : 'ml-n4'"
                          @click="chatUser"
                        >
                          Chat
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn
                          color="success"
                          variant="text"
                          prepend-icon="mdi-heart-outline"
                          :class="this.$vuetify.display.mobile ? '' : 'ml-n4'"
                          @click="close"
                        >
                          Favorite
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:subtitle>
                    <div class="mt-1">
                      <v-icon
                        color="black"
                        class="ml-n1"
                        icon="mdi-map-marker"
                      ></v-icon>
                      <span class="text-black"
                        >{{ Listing.city.title }},
                        {{ Listing.city.country.name }}</span
                      >
                    </div>
                  </template>
                  <v-divider></v-divider>
                  <v-card-text class="pt-4">
                    <div>
                      <span class="font-weight-black text-h6">
                        {{ Listing.title }}
                      </span>
                      <v-container class="ml-n2 mb-6">
                        <v-row
                          :class="
                            this.$vuetify.display.mobile ? 'text-caption' : ''
                          "
                        >
                          <v-col>
                            <!-- Content on the left -->
                            <v-row>
                              <v-col cols="6">
                                <div>Type</div>
                              </v-col>
                              <v-col cols="6">
                                <div
                                  class="font-weight-black"
                                  v-if="Listing.category.name"
                                >
                                  {{ Listing.category.name }}
                                </div>
                                <div v-else class="font-weight-black">
                                  {{ "N/A" }}
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div>Property Age</div>
                              </v-col>
                              <v-col cols="6">
                                <div
                                  v-if="Listing.activeRelation.age"
                                  class="font-weight-black"
                                >
                                  {{ Listing.activeRelation.age }}
                                </div>
                                <div v-else class="font-weight-black">
                                  {{ "N/A" }}
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div>Updated</div>
                              </v-col>
                              <v-col cols="6">
                                <div
                                  v-if="Listing.activeRelation.updated_at"
                                  class="font-weight-black"
                                >
                                  {{
                                    formatDate(
                                      Listing.activeRelation.updated_at
                                    )
                                  }}
                                </div></v-col
                              >
                            </v-row>
                          </v-col>

                          <!-- Vertical Divider -->
                          <v-divider
                            :thickness="2"
                            color="black"
                            vertical
                            class="mx-auto"
                          ></v-divider>

                          <v-col>
                            <!-- Content on the right -->
                            <v-row>
                              <v-col cols="6">
                                <div>Purpose</div>
                              </v-col>
                              <v-col cols="6">
                                <div
                                  class="font-weight-black"
                                  v-if="Listing.activeRelation.propertyType"
                                >
                                  {{ Listing.activeRelation.propertyType }}
                                </div>
                                <div v-else class="font-weight-black">
                                  {{ "N/A" }}
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div>Furnished</div>
                              </v-col>
                              <v-col cols="6">
                                <div class="font-weight-black">
                                  {{
                                    Listing.activeRelation.isFurnished
                                      ? "Furnished"
                                      : "Unfurnished"
                                  }}
                                </div></v-col
                              >
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <v-list class="ml-n2">
                      <v-list-item v-if="Listing.activeRelation.brand">
                        <span>Brand:</span>
                        {{ Listing.activeRelation.brand }}
                      </v-list-item>
                      <v-list-item
                        v-if="
                          Listing.activeRelation.age &&
                          Listing.activeRelation.age !== 'null'
                        "
                      >
                        <span>Age (Years):</span>
                        {{ Listing.activeRelation.age }}
                      </v-list-item>
                      <v-list-item v-if="Listing.activeRelation.condition">
                        <span>Condition:</span>
                        {{ Listing.activeRelation.condition }}
                      </v-list-item>
                      <v-list-item v-if="Listing.activeRelation.usage">
                        <span>Usage:</span>
                        {{ Listing.activeRelation.usage }}
                      </v-list-item>
                      <v-list-item v-if="Listing.activeRelation.warranty">
                        <span>Warranty(years):</span>
                        {{ Listing.activeRelation.warranty }}
                      </v-list-item>
                      <v-list-item
                        v-if="Listing.activeRelation.occupancyStatus"
                      >
                        <span>Occupancy Status:</span>
                        {{ Listing.activeRelation.occupancyStatus }}
                      </v-list-item>

                      <v-list-item v-if="Listing.activeRelation.isFurnished">
                        <span>Furnishing:</span>
                        {{
                          Listing.activeRelation.isFurnished
                            ? "Furnished"
                            : "Unfurnished"
                        }}
                      </v-list-item>

                      <!-- Bathrooms -->
                      <v-list-item v-if="Listing.activeRelation.bathrooms">
                        <span>Bathrooms:</span>
                        {{ Listing.activeRelation.bathrooms }}
                      </v-list-item>

                      <!-- Bedrooms -->
                      <v-list-item v-if="Listing.activeRelation.bedrooms">
                        <span>Bedrooms:</span>
                        {{ Listing.activeRelation.bedrooms }}
                      </v-list-item>

                      <!-- Maintenance Fee -->
                      <v-list-item v-if="Listing.activeRelation.maintenanceFee">
                        <span>Maintenance Fee:</span>
                        {{ Listing.activeRelation.maintenanceFee }}
                      </v-list-item>

                      <!-- Minimum Contract Period -->
                      <v-list-item
                        v-if="Listing.activeRelation.minimumContractPeriod"
                      >
                        <span>Minimum Contract Period (years):</span>
                        {{ Listing.activeRelation.minimumContractPeriod }}
                      </v-list-item>

                      <!-- Notice Period -->
                      <v-list-item v-if="Listing.activeRelation.noticePeriod">
                        <span>Notice Period (months):</span>
                        {{ Listing.activeRelation.noticePeriod }}
                      </v-list-item>

                      <!-- Price Per Year -->
                      <v-list-item v-if="Listing.activeRelation.pricePerYear">
                        <span>Price Per Year:</span>
                        {{ Listing.activeRelation.pricePerYear }}
                      </v-list-item>
                      <!-- Rent Paid -->
                      <v-list-item v-if="Listing.activeRelation.rentPaid">
                        <span>Rent Paid:</span>
                        {{ Listing.activeRelation.rentPaid }}
                      </v-list-item>

                      <!-- Size -->
                      <v-list-item v-if="Listing.activeRelation.size">
                        <span>Size:</span>
                        {{ Listing.activeRelation.size }}
                      </v-list-item>

                      <!-- Make/Model -->
                      <v-list-item v-if="Listing.activeRelation.makeModel">
                        <span>Make/Model:</span>
                        {{ Listing.activeRelation.makeModel }}
                      </v-list-item>

                      <!-- Body Type -->
                      <v-list-item v-if="Listing.activeRelation.bodyType">
                        <span>Body Type:</span>
                        {{ Listing.activeRelation.bodyType }}
                      </v-list-item>

                      <!-- Is Insured -->
                      <v-list-item v-if="Listing.activeRelation.isInsured">
                        <span>Insurance:</span>
                        {{ Listing.activeRelation.isInsured }}
                      </v-list-item>

                      <!-- Kilometers -->
                      <v-list-item v-if="Listing.activeRelation.kilometers">
                        <span>Mileage (Kilometers):</span>
                        {{ Listing.activeRelation.kilometers }}
                      </v-list-item>

                      <!-- Price -->
                      <v-list-item v-if="Listing.activeRelation.price">
                        <span>Price:</span>
                        {{ Listing.activeRelation.price }}
                      </v-list-item>

                      <!-- Specs -->
                      <v-list-item v-if="Listing.activeRelation.specs">
                        <span>Specs:</span>
                        {{ Listing.activeRelation.specs }}
                      </v-list-item>

                      <!-- Year -->
                      <v-list-item v-if="Listing.activeRelation.year">
                        <span>Year:</span>
                        {{ Listing.activeRelation.year }}
                      </v-list-item>

                      <!-- Trim -->
                      <v-list-item v-if="Listing.activeRelation.trim">
                        <span>Trim:</span>
                        {{ Listing.activeRelation.trim }}
                      </v-list-item>

                      <!-- Phone Number -->
                      <v-list-item v-if="Listing.activeRelation.phoneNumber">
                        <span>Phone Number:</span>
                        {{ Listing.activeRelation.phoneNumber }}
                      </v-list-item>

                      <!-- Last Posted (updated_at) -->
                      <v-list-item v-if="Listing.activeRelation.updated_at">
                        <span>Posted:</span>
                        {{ formatDate(Listing.activeRelation.updated_at) }}
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>

                <!-- -------DESCRIPTION------- -->
                <v-card
                  :class="this.$vuetify.display.mobile ? 'mr-n9 mt-2' : 'mt-2'"
                  v-if="Listing.activeRelation.description"
                  elevation="0"
                >
                  <template v-slot:title>
                    <span class="font-weight-black">DESCRIPTION</span>
                  </template>
                  <v-divider></v-divider>
                  <v-card-text class="pt-4">
                    {{ Listing.activeRelation.description }}
                  </v-card-text>
                </v-card>

                <!-- -------AMENITIES------- -->
                <v-card
                  :class="this.$vuetify.display.mobile ? 'mr-n9 mt-2' : 'mt-2'"
                  v-if="Listing.category.slug === 'property'"
                  elevation="0"
                >
                  <template v-slot:title>
                    <span class="font-weight-black">Amenities</span>
                  </template>
                  <v-divider></v-divider>

                  <v-card-text class="pt-4">
                    <v-row>
                      <v-col
                        v-if="Listing.activeRelation.maidsRoom"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('maidsRoom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Maids Room
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.studyRoom && Listing.activeRelation.studyRoom != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('studyRoom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Study Room
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.centralACHeating && Listing.activeRelation.centralACHeating != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('centralACHeating')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Central AC Heating
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.conciergeService && Listing.activeRelation.conciergeService != 'false'" 
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('conciergeService')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Concierge Service
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.balcony && Listing.activeRelation.balcony != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('balcony')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Balcony
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.privateGarden && Listing.activeRelation.privateGarden != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('privateGarden')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Private Garden
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.privatePool && Listing.activeRelation.privatePool != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('privatePool')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Private Pool
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.privateGym && Listing.activeRelation.privateGym != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('privateGym')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Private Gym
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.privateJacuzzi && Listing.activeRelation.privateJacuzzi != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('privateJacuzzi')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Private Jacuzzi
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.sharedPool && Listing.activeRelation.sharedPool != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('sharedPool')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Shared Pool
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.sharedSpa && Listing.activeRelation.sharedSpa != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('sharedSpa')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Shared Spa
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.sharedGym && Listing.activeRelation.sharedGym != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('sharedGym')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Shared Gym
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.security && Listing.activeRelation.security != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('security')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Security
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.maidService && Listing.activeRelation.maidService != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('maidService')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Maid Service
                          </div>
                        </v-card>
                      </v-col>
                      <!-- second butch -->

                      <v-col
                        v-if="Listing.activeRelation.coveredParking && Listing.activeRelation.coveredParking != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('coveredParking')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Covered Parking
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.builtInWardrobes && Listing.activeRelation.builtInWardrobes != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('builtInWardrobes')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Built-in Wardrobes
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.walkInCloset && Listing.activeRelation.walkInCloset != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('walkInCloset')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Walk-in Closet
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.builtInKitchenAppliances && Listing.activeRelation.builtInKitchenAppliances != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('builtInKitchenAppliances')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Built-in Kitchen Appliances
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.viewOfWater && Listing.activeRelation.viewOfWater != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('viewOfWater')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            View of Water
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.viewOfLandmark && Listing.activeRelation.viewOfLandmark != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('viewOfLandmark')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            View of Landmark
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.petsAllowed && Listing.activeRelation.petsAllowed  != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('petsAllowed')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Pets Allowed
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.doubleGlazedWindows && Listing.activeRelation.doubleGlazedWindows != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('doubleGlazedWindows')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Double Glazed Windows
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.dayCareCenter && Listing.activeRelation.dayCareCenter  != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('dayCareCenter')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Day Care Center
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.electricityBackup && Listing.activeRelation.electricityBackup  != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('electricityBackup')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Electricity Backup
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.firstAidMedicalCenter && Listing.activeRelation.firstAidMedicalCenter != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('firstAidMedicalCenter')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            First Aid Medical Center
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.serviceElevators &&  Listing.activeRelation.serviceElevators != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('serviceElevators')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Service Elevators
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.prayerRoom &&  Listing.activeRelation.prayerRoom != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('prayerRoom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Prayer Room
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.laundryRoom && Listing.activeRelation.laundryRoom != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('laundryRoom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Laundry Room
                          </div>
                        </v-card>
                      </v-col>
                      <!-- third butch -->

                      <v-col
                        v-if="Listing.activeRelation.broadbandInternet && Listing.activeRelation.broadbandInternet != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('broadbandInternet')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Broadband Internet
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.satelliteCableTV && Listing.activeRelation.satelliteCableTV != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('satelliteCableTV')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Satellite Cable TV
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.businessCenter && Listing.activeRelation.businessCenter != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('businessCenter')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Business Center
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.intercom && Listing.activeRelation.intercom != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('intercom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Intercom
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.atmFacility && Listing.activeRelation.atmFacility != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('atmFacility')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            ATM Facility
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.kidsPlayArea && Listing.activeRelation.kidsPlayArea != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('kidsPlayArea')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Kids Play Area
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.receptionWaitingRoom && Listing.activeRelation.receptionWaitingRoom != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('receptionWaitingRoom')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Reception Waiting Room
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.maintenanceStaff && Listing.activeRelation.maintenanceStaff != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('maintenanceStaff')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Maintenance Staff
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.cctvSecurity && Listing.activeRelation.cctvSecurity != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('cctvSecurity')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            CCTV Security
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.cafeteriaOrCanteen && Listing.activeRelation.cafeteriaOrCanteen != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('cafeteriaOrCanteen')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Cafeteria or Canteen
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.sharedKitchen && Listing.activeRelation.sharedKitchen != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('sharedKitchen')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Shared Kitchen
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.facilitiesForDisabled && Listing.activeRelation.facilitiesForDisabled != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('facilitiesForDisabled')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Facilities for Disabled
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.storageAreas && Listing.activeRelation.storageAreas != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('storageAreas')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Storage Areas
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        v-if="Listing.activeRelation.cleaningServices && Listing.activeRelation.cleaningServices != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('cleaningServices')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Cleaning Services
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.barbequeArea && Listing.activeRelation.barbequeArea != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('barbequeArea')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Barbeque Area
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.lobbyInBuilding && Listing.activeRelation.lobbyInBuilding != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('lobbyInBuilding')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Lobby in Building
                          </div>
                        </v-card>
                      </v-col>

                      <v-col
                        v-if="Listing.activeRelation.wasteDisposal && Listing.activeRelation.wasteDisposal  != 'false'"
                        cols="6"
                        md="3"
                      >
                        <v-card
                          align="center"
                          class="rounded-lg pa-5 bg-grey-lighten-4"
                          elevation="0"
                        >
                          <v-img
                            :src="getImage('wasteDisposal')"
                            class="rounded-lg"
                            max-width="35"
                            max-height="35"
                          ></v-img>
                          <div
                            class="mt-2 text-center font-weight-black text-caption"
                          >
                            Waste Disposal
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <!-- -------MAP VIEW ------- -->
                <v-card
                  :class="
                    this.$vuetify.display.mobile
                      ? 'mr-n9 mt-2 mb-3'
                      : 'mt-2 mb-3'
                  "
                  elevation="0"
                >
                  <template v-slot:title>
                    <div class="font-weight-black">Map View</div>
                    <div class="text-black text-caption">
                      {{ Listing.city.title }},
                      {{ Listing.city.country.name }}
                    </div>
                  </template>
                  <v-card-text class="pt-4">
                    <GoogleMap :cities="Listing.city"></GoogleMap>
                  </v-card-text>
                </v-card>
                <v-divider></v-divider>
                <v-card
                  :class="
                    this.$vuetify.display.mobile
                      ? 'mr-n9 mt-2 mb-3'
                      : 'mt-2 mb-n3'
                  "
                  elevation="0"
                >
                  <v-form
                    ref="form"
                    @submit.prevent="validate"
                    v-model="isFormValid"
                  >
                    <v-row>
                      <v-col cols="12" class="mt-2 mb-n3">
                        <v-autocomplete
                          v-model="editedItem.status"
                          label="Select Status"
                          :items="statuses"
                          item-text="title"
                          item-value="id"
                          @update:modelValue="setStatus"
                          variant="outlined"
                          :rules="[(v) => !!v || 'Status is required']"
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-date-input
                          v-model="editedItem.expires_at"
                          label="Expiry Date"
                          color="blue"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          format="MM-DD-YYYY"
                          variant="outlined"
                        ></v-date-input>
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-date-input
                          v-model="editedItem.approvedDate"
                          label="Approved Date"
                          prepend-icon=""
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          color="blue"
                          format="MM-DD-YYYY"
                          variant="outlined"
                        ></v-date-input>
                      </v-col>

                      <v-col cols="12" class="mt-n2">
                        <v-textarea
                          v-model="editedItem.rejectReason"
                          label="Rejection Reason (optional)"
                          color="blue"
                          variant="outlined"
                        ></v-textarea>
                      </v-col>
                    </v-row>

                    <div class="">
                      <v-btn
                        class="mt-4"
                        block
                        variant="outlined"
                        color="blue"
                        :disabled="!isFormValid"
                        @click="save"
                      >
                        Submit
                      </v-btn>
                    </div>
                  </v-form>
                </v-card>
              </v-col>

              <!-- ---- USER PROFILE ---- -->
              <v-col cols="12" md="4">
                <v-card
                  :class="this.$vuetify.display.mobile ? 'mx-n8 mb-n10' : ''"
                  rounded="lg"
                  :elevation="this.$vuetify.display.mobile ? '0' : '1'"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="4">
                        <div class="rounded-lg">
                          <v-img
                            height="80"
                            src="@/assets/profile.png"
                            cover
                            class="rounded-lg"
                          >
                          </v-img>
                        </div>
                      </v-col>
                      <v-col cols="8">
                        <div class="mt-3 ml-n3">
                          <span class="font-weight-black">
                            {{ Listing.user?.fullName }}</span
                          >
                          <div class="">
                            <span class="font-weight-black text-caption">
                              EMAIL:
                            </span>
                            {{ Listing.user?.email }}
                          </div>
                          <div class="">
                            <span class="font-weight-black text-caption">
                              Tel:
                            </span>
                            {{ Listing.user?.phoneNumber }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      variant="tonal"
                      prepend-icon="mdi-email"
                      class="ml-4"
                      @click="emailDialogOn"
                    >
                      Email
                    </v-btn>
                    <v-btn
                      color="error"
                      variant="tonal"
                      prepend-icon="mdi-phone"
                      class="ml-2"
                      @click="callDialog = true"
                    >
                      Call
                    </v-btn>

                    <v-btn
                      color="success"
                      variant="tonal"
                      prepend-icon="mdi-whatsapp"
                      class="ml-2"
                      :href="whatsappUrl"
                      target="_blank"
                    >
                      Whatsapp
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <div>
                  <!-- Call Dialog -->
                  <v-dialog v-model="callDialog" width="auto">
                    <v-card max-width="500" class="rounded-lg pa-2">
                      <v-card-title>
                        <v-toolbar class="bg-white">
                          <v-row class="text-caption">
                            <v-col cols="4">
                              <div class="rounded-lg">
                                <v-img
                                  height="80"
                                  src="@/assets/profile.png"
                                  cover
                                  class="rounded-lg"
                                >
                                </v-img>
                              </div>
                            </v-col>
                            <v-col cols="8">
                              <div class="mt-5 ml-n3">
                                <span class="font-weight-black">
                                  {{ Listing.user?.fullName }}</span
                                >
                                <div class="">
                                  <span class="font-weight-black text-caption">
                                    EMAIL:
                                  </span>
                                  {{ Listing.user?.email }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            icon="mdi-close"
                            variant="plain"
                            @click="callDialog = false"
                          >
                          </v-btn>
                        </v-toolbar>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text class="text-center mt-3">
                        Don't forget to mention the property title
                        <span class="text-error">{{ Listing.title }}</span>
                        when you call.
                      </v-card-text>
                      <template v-slot:actions>
                        <v-btn
                          class="ms-auto"
                          variant="tonal"
                          color="error"
                          block
                          @click="dialog = false"
                        >
                          {{ Listing.user?.phoneNumber }}</v-btn
                        >
                      </template>
                    </v-card>
                  </v-dialog>

                  <!-- Email Dialog -->
                  <v-dialog v-model="emailDialog" width="auto">
                    <v-card width="600" class="rounded-lg">
                      <v-card-title>
                        <v-toolbar class="bg-white">
                          Inquire About {{ Listing.title }}
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            icon="mdi-close"
                            variant="plain"
                            @click="emailDialog = false"
                          >
                          </v-btn>
                        </v-toolbar>
                      </v-card-title>
                      <v-card-subtitle class="mt-n3">
                        <v-row class="text-caption">
                          <v-col cols="4">
                            <div class="rounded-lg">
                              <v-img
                                height="80"
                                :src="`data:${Listing.files[0]?.mime_type};base64,${Listing.files[0]?.base64_data}`"
                                cover
                                class="rounded-lg ml-1"
                              >
                              </v-img>
                            </div>
                          </v-col>
                          <v-col cols="8" class="mt-4">
                            <div class="d-flex">
                              <span class="font-weight-black text-error"
                                >{{ "USD" + " " }} {{ Listing.price }}</span
                              >
                            </div>
                            <span class="text-black"
                              >{{ Listing.city.title }},
                              {{ Listing.city.country.name }}</span
                            ></v-col
                          ></v-row
                        >
                      </v-card-subtitle>
                      <v-card-text class="mx-n1">
                        <!-- form here -->
                        <v-form ref="form" v-model="isFormValid">
                          <v-textarea
                            v-model="editedItem.message"
                            auto-grow
                            label="Message"
                            class="mt-1"
                            variant="outlined"
                            :rules="[(v) => !!v || 'message is required']"
                          ></v-textarea>
                          <v-text-field
                            v-model="editedItem.fullName"
                            label="Full Name"
                            class="mt-1"
                            variant="outlined"
                            :rules="[(v) => !!v || 'full name is required']"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                            class="mt-1"
                            type="email"
                            variant="outlined"
                            :rules="[(v) => !!v || 'email is required']"
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.phoneNumber"
                            label="Phone Number"
                            class="mt-1"
                            variant="outlined"
                            :rules="[(v) => !!v || 'phone number is required']"
                          ></v-text-field>
                        </v-form>
                      </v-card-text>
                      <template v-slot:actions>
                        <v-col cols="12" class="mt-n4">
                          <div class="text-center">
                            <v-btn
                              class="ms-auto"
                              variant="tonal"
                              color="grey"
                              block
                              :disabled="!isFormValid"
                              @click="inquireListing"
                            >
                              <span class="text-capitalize"
                                >Inquire Now</span
                              ></v-btn
                            >
                            <div class="mt-6 text-caption">
                              <span class="text-grey">
                                By clicking on 'Inquire Now', I agree to the
                                <v-btn
                                  @click="terms"
                                  variant="text"
                                  class="text-none ml-n1"
                                  size="x-small"
                                  color="blue"
                                  >Terms and Conditions</v-btn
                                >
                                <span class="ml-n1"> and </span>
                                <v-btn
                                  @click="policies"
                                  variant="text"
                                  class="text-none ml-n2"
                                  size="x-small"
                                  color="blue"
                                  >Privacy Policies</v-btn
                                >
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </template>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMapComponent.vue";
import CityService from "../../services/cities.services";
import CountryService from "../../services/country.services.js";
import CategoryService from "../../services/category.services";
import ListingService from "../../services/listing.service.js";
import ChatService from "@/services/chat.service.js";
import DisputeService from "@/services/disputes.service.js";
import moment from "moment";

export default {
  name: "GeneralListing",
  props: {
    Listing: {
      type: Object,
      required: true,
    },
  },
  components: {
    GoogleMap,
  },

  data: () => ({
    value: 1,
    AllImages: false,
    emailDialog: false,
    whatsappUrl: process.env.VUE_APP_WHATSAPP_URL,
    callDialog: false,
    isFormValid: false,
    country: {},
    cities: [],
    isActive: { value: false },

    editedItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      approvedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
      message: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      listingId: "",
      description: "",
    },

    defaultItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      approvedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
      message: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      listingId: "",
      description: "",
    },

    statuses: [
      { id: 1, title: "Approved" },
      { id: 2, title: "Pending" },
      { id: 3, title: "Rejected" },
    ],
    isFormValid: false,

    ecosystem: [
      {
        text: "Notification",
        route: "/notification",
        icon: "mdi-bell-outline",
      },
      {
        text: "My Searches",
        route: "/my-search",
        icon: "mdi-feature-search-outline",
      },
      {
        text: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        text: "My Ads",
        route: "/my-ads",
        icon: "mdi-view-list-outline",
      },
    ],
    bottomNav: [
      {
        name: "Home",
        route: "/",
        icon: "mdi-home",
      },
      {
        name: "Search",
        route: "",
        icon: "mdi-magnify",
        menu: "search",
      },
      {
        name: "Post an Ad",
        route: "/place-ad",
        icon: "mdi-plus-circle-outline",
      },
      {
        name: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        name: "menu",
        route: "",
        icon: "mdi-menu",
        menu: "menu",
      },
    ],

    fetureCategories: [
      {
        icon: "motors",
        title: "Motors",
        subcategories: [
          { title: "Cars" },
          { title: "Motorcycles" },
          { title: "Auto Accessories & Parts" },
          { title: "Heavy Vehicles" },
        ],
        allInText: "All in Motors",
      },
      {
        icon: "property-for-rent",
        title: "Property for Rent",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "Rooms For Rent" },
          { title: "Monthly Short Term" },
        ],
        allInText: "All in Property for Rent",
      },
      {
        icon: "property-for-sale",
        title: "Property for Sale",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "New Projects" },
          { title: "Off-Plan" },
        ],
        allInText: "All in Property for Sale",
      },
      {
        icon: "classified",
        title: "Classifieds",
        subcategories: [
          { title: "Electronics" },
          { title: "Computers & Networking" },
          { title: "Clothing & Accessories" },
          { title: "Jewelry & Watches" },
        ],
        allInText: "All in Classifieds",
      },
      {
        icon: "jobs",
        title: "Jobs",
        subcategories: [
          { title: "Accounting / Finance" },
          { title: "Engineering" },
          { title: "Sales / Business Development" },
          { title: "Secretarial / Front Office" },
        ],
        allInText: "All in Jobs",
      },
      {
        icon: "community",
        title: "Community",
        subcategories: [
          { title: "Freelancers" },
          { title: "Home Maintenance" },
          { title: "Other Services" },
          { title: "Tutors & Classes" },
        ],
        allInText: "All in Community",
      },
      {
        icon: "business-industrial",
        title: "Business & Industrial",
        subcategories: [
          { title: "Businesses for Sale" },
          { title: "Construction" },
          { title: "Food & Beverage" },
          { title: "Industrial Supplies" },
        ],
        allInText: "All in Business & Industrial",
      },
      {
        icon: "home-appliances",
        title: "Home Appliances",
        subcategories: [
          { title: "Large Appliances / White Goods" },
          { title: "Small Kitchen Appliances" },
          { title: "Outdoor Appliances" },
          { title: "Small Bathroom Appliances" },
        ],
        allInText: "All in Home Appliances",
      },
      {
        icon: "furniture-home-garden",
        title: "Furniture, Home & Garden",
        subcategories: [
          { title: "Furniture" },
          { title: "Home Accessories" },
          { title: "Garden & Outdoor" },
          { title: "Lighting & Fans" },
        ],
        allInText: "All in Furniture, Home & Garden",
      },
      {
        icon: "mobile-phones-pdas",
        title: "Mobile Phones & Tablets",
        subcategories: [
          { title: "Mobile Phones" },
          { title: "Mobile Phone & Tablet Accessories" },
          { title: "Tablets" },
          { title: "Other Mobile Phones & Tablets" },
        ],
        allInText: "All in Mobile Phones & Tablets",
      },
    ],

    chatDetail: {
      vendor_id: "",
      customer_id: "",
    },

    menuActive: false,
    searchActive: false,
    categories: {},
    listings: [],
    chat: [],
    searchQuery: "",
  }),
  computed: {
    loggedInUser() {
      return this.$store.state.auth.user;
    },

    selectedItem() {
      return this.$store.getters["category/getSelectedItem"];
    },

    user() {
      return this.loggedInUser || {};
    },

    groupedListings() {
      return this.listings.reduce((grouped, listing) => {
        const categorySlug = listing.category.slug;
        if (!grouped[categorySlug]) {
          grouped[categorySlug] = [];
        }
        grouped[categorySlug].push(listing);
        return grouped;
      }, {});
    },

    filteredListings() {
      return this.listings.filter((listing) => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          listing.title.toLowerCase().includes(searchQueryLower) ||
          listing.description.toLowerCase().includes(searchQueryLower)
        );
      });
    },
  },

  created() {
    this.getDefaultCountry();
    this.getCategories();
    this.getlistings();
    if (this.selectedItem) {
      if (!this.editedItem.editedItem) {
        this.editedItem.editedItem = {};
      }

      this.editedItem.status = this.selectedItem.status
        ? this.selectedItem.status
        : this.editedItem.status;

      this.editedItem.expires_at = this.selectedItem.expires_at
        ? this.selectedItem.expires_at
        : new Date();

      this.editedItem.approvedDate = this.selectedItem.approvedDate
        ? this.selectedItem.approvedDate
        : new Date();

      this.editedItem.rejectReason = this.selectedItem.rejectReason
        ? this.selectedItem.rejectReason
        : this.editedItem.rejectReason;
    }
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    getCategoryName(slug) {
      const category = this.categories.find((cat) => cat.slug === slug);
      return category ? category.title : "Unknown Category";
    },

    validate() {
      this.$refs.form.validate();
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    backToSearch() {
      this.$router.push("/");
    },

    formatDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY");
    },

    emailDialogOn() {
      if (this.loggedInUser) {
        this.editedItem.fullName =
          this.loggedInUser.fullName ?? this.loggedInUser.fullName;
        this.editedItem.email =
          this.loggedInUser.email ?? this.loggedInUser.email;
        this.editedItem.phoneNumber =
          this.loggedInUser.phoneNumber ?? this.loggedInUser.phoneNumber;
      }
      this.editedItem.message = "Hi, i would like ...";
      this.emailDialog = true;
    },

    convertToCurrency(value) {
      let number = value;

      if (typeof value === "string") {
        number = parseFloat(value.replace(/[^0-9.-]+/g, ""));
      }

      if (!isNaN(number)) {
        return "USD" + " " + number.toLocaleString("en-US");
      } else {
        return "Invalid number";
      }
    },

    getCategories() {
      return CategoryService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    formatKey(key) {
      // Convert camelCase to a more human-readable format
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },

    getlistings() {
      return ListingService.getApprovedOpenListing().then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            // console.log("the list", this.listings);
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    inquireListing() {
      this.editedItem.listingId = this.selectedItem.id;
      return ListingService.inquireListing(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.editedItem = this.defaultItem;
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    reportListing() {
      this.editedItem.listingId = this.selectedItem.id;
      return DisputeService.store(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.editedItem = this.defaultItem;
            this.loading = false;
            this.isActive.value = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    chatUser() {
      if (this.loggedInUser) {
        this.chatDetail.vendor_id = this.loggedInUser._id;
        this.chatDetail.customer_id = this.selectedItem.user._id;
        return ChatService.getOrCreateChat(this.chatDetail).then(
          (response) => {
            if (response.status == 200) {
              this.chat = response.data.data;
              this.$store.dispatch("chat/setCreatedChat", this.chat);
              this.$router.push("/chats");
              this.$store.dispatch("alert/success", response.data.message);
              console.log("the list", this.chat);
              this.loading = false;
            }
          },
          (error) => {
            this.chat = [];
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        );
      } else {
        this.$router.push("/");
        this.$store.dispatch("alert/error", "Login to chat");
      }
    },

    getDefaultCountry() {
      return CountryService.defaultCountry().then(
        (response) => {
          if (response.status == 200) {
            this.country = response.data.data;
            this.getcities(this.country.id);
            this.loading = false;
          } else {
            this.country = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.country = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    // get cities
    getcities(countryId) {
      return CityService.getCountryCities(countryId).then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data.slice(0, 6);
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    async getlistingFile(gridfs_id) {
      return ListingService.getListingFile(gridfs_id).then(
        (response) => {
          if (response.status == 200) {
            const mimeType = response.headers["content-type"]; // Get the MIME type from the response
            const base64Image = this.convertToBase64(response.data, mimeType); // Convert binary data to Base64 with dynamic MIME
            return base64Image;

            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.console.error);
            return null;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          return null;
        }
      );
    },

    convertToBase64(binaryData, mimeType) {
      // Ensure binary data is a Uint8Array
      const base64String = btoa(
        new Uint8Array(binaryData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return `data:${mimeType};base64,${base64String}`;
    },

    loginOrSignup() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push("/");
    },

    showAllImages() {
      this.AllImages = true;
    },

    routeTo(route) {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || route);
    },

    placeAd() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/place-ad");
    },

    toggle(item) {
      if ((!item.route && item.menu === "menu") || "search") {
        item.menu === "menu"
          ? (this.menuActive = !this.menuActive)
          : (this.searchActive = !this.searchActive);
      }
    },

    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

    setStatus(item) {
      console.log("jus see me", item);
      this.editedItem.status = item;
    },

    save() {
      this.Listing.status = this.editedItem.status;
      this.Listing.expires_at = this.editedItem.expires_at;
      this.Listing.approvedDate = this.editedItem.approvedDate;
      this.Listing.rejectReason = this.editedItem.rejectReason;
      this.editedItem = this.Listing;
      this.editedItem.id = this.Listing.id;

      this.editedItem.userId = this.loggedInUser;
      console.log("we are here", this.editedItem);
      ListingService.update(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    getImage(amenityKey) {
      try {
        return require(`@/assets/${amenityKey}.svg`);
      } catch (error) {
        return require("@/assets/balcony.svg");
      }
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },
  },
};
</script>
<style scoped>
.v-input__icon--append .v-icon {
  color: purple;
}

.custom-card {
  background-color: rgba(0, 0, 0, 0.6);
}

.hover-effect {
  background-color: transparent !important;
  transition: color 0.3s ease;
}

.hover-effect:hover {
  background-color: transparent !important;
  color: blue !important;
}

.v-list-item-title {
  color: inherit;
}
</style>
