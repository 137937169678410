import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import logoutPlugin from './plugins/logoutPlugin.js';
import mitt from "mitt";

const emitter = mitt();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(loadFonts);
app.use(logoutPlugin);
app.use(vuetify);

// Set global event bus
app.config.globalProperties.emitter = emitter;

app.mount("#app");