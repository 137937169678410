<template>
  <div class="">
    <v-app>
      <!-- app bar -->
      <v-app-bar
        :elevation="0"
        v-if="!this.$vuetify.display.mobile"
        color="grey-lighten-4 px-11"
        variant="outlined"
      >
        <!-- Clickable Logo -->
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          @click="logoRoute"
        />

        <v-spacer></v-spacer>
        <div v-for="item in ecosystem" :key="item" class="mx-5 text-grey">
          <v-btn @click="routeTo(item.route)" density="compact" stacked>
            <v-icon size="x-small">{{ item.icon }}</v-icon>
            <span class="text-caption">{{ item.text }}</span>
          </v-btn>
        </div>

        <!-- Login or Signup button for larger screens -->
        <div v-if="$vuetify.display.mdAndUp">
          <div v-if="!loggedInUser">
            <v-btn
              variant="text"
              class="text-caption mr-10"
              @click="loginOrSignup"
            >
              Login or Signup
            </v-btn>
          </div>
          <div v-else>
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-avatar
                  v-bind="props"
                  size="small"
                  class="mr-8"
                  color="error"
                >
                </v-avatar>
              </template>
              <v-list>
                <v-list-item @click="handleLogout">
                  <v-list-item-title>
                    <div class="pa-1">
                      <v-icon
                        size="small"
                        color="blue"
                        icon="mdi-logout-variant"
                      >
                      </v-icon>
                      Logout
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-btn
          @click="placeAd"
          color="error"
          class="error text-caption mr-16"
          variant="flat"
        >
          Place Your Ad</v-btn
        >
      </v-app-bar>

      <!-- --------------------------- MAIN SECTION ---------------------------- -->

      <!-- main content here -->
      <v-main class="mx-16 ma-1 mt-2">
        <v-row class="mx-5">
          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <v-img max-height="280" src="../assets/web_ad.jpeg"></v-img>
          </v-col>

          <!-- ----------CATEGORY INDICATOR----------- -->
          <v-col
            cols="12"
            v-if="$vuetify.display.mdAndUp"
            :class="this.$vuetify.display.mobile ? 'mx-n16' : 'mt-n4 mb-n8'"
          >
            <v-icon
              color="error"
              size="x-small"
              icon="mdi-chevron-right"
            ></v-icon>
            <v-btn
              variant="text"
              @click="backToSearch"
              class="text-caption text-primary"
            >
              Back to search
            </v-btn>
            <span class="text-caption">
              {{ selectedItem.category.name }}
            </span>
            <v-icon
              color="black"
              size="x-small"
              icon="mdi-chevron-right"
            ></v-icon>
            <span class="text-caption">
              {{ selectedItem.title }}
            </span>
          </v-col>

          <!-- ----- PRODUCTS ------ -->
          <v-col cols="12">
            <v-row
              class="ma-2"
              :class="this.$vuetify.display.mobile ? 'mx-n16' : ''"
            >
              <v-col cols="12" md="12" @click="showAllImages">
                <v-row>
                  <v-col cols="12" md="6">
                    <div
                      :class="
                        this.$vuetify.display.mobile
                          ? 'rounded-lg mx-n12 mt-n8'
                          : 'rounded-lg mr-n7'
                      "
                    >
                      <v-img
                        height="405"
                        :src="`data:${selectedItem.files[0]?.mime_type};base64,${selectedItem.files[0]?.base64_data}`"
                        cover
                        class="rounded-lg"
                        aspect-ratio="16/9"
                      >
                        <div class="ma-3">
                          <span
                            class="d-flex justify-start"
                            v-if="$vuetify.display.mobile"
                            ><v-icon
                              color="error"
                              icon="mdi-chevron-left"
                              size="large"
                              @click.stop="toList"
                            ></v-icon
                          ></span>
                          <span
                            v-if="selectedItem.verified"
                            :class="
                              $vuetify.display.mobile
                                ? 'd-flex justify-end mt-n7'
                                : 'd-flex justify-start'
                            "
                          >
                            <v-btn
                              color="white"
                              size="small"
                              prepend-icon="mdi-check-circle-outline"
                              class="text-capitalize"
                            >
                              <span class="ml-n1">Verified</span>
                            </v-btn>
                          </span>
                        </div>
                      </v-img>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-col cols="12" v-if="!this.$vuetify.display.mobile">
                      <div
                        :class="
                          this.$vuetify.display.mobile
                            ? 'rounded-lg mx-n9 mt-n3'
                            : 'rounded-lg mt-n3'
                        "
                      >
                        <v-img
                          height="200"
                          :src="`data:${selectedItem.files[1]?.mime_type};base64,${selectedItem.files[1]?.base64_data}`"
                          cover
                          class="rounded-lg"
                          aspect-ratio="16/9"
                        >
                        </v-img>
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="!this.$vuetify.display.mobile">
                      <div
                        :class="
                          this.$vuetify.display.mobile
                            ? 'rounded-lg mx-n9 mt-n3'
                            : 'rounded-lg mt-n5'
                        "
                      >
                        <v-img
                          height="200"
                          :src="`data:${selectedItem.files[2]?.mime_type};base64,${selectedItem.files[2]?.base64_data}`"
                          cover
                          class="rounded-lg"
                          aspect-ratio="16/9"
                        >
                          <div
                            class="ma-2"
                            v-if="selectedItem && selectedItem.files.length > 2"
                          >
                            <v-icon icon="mdi-image-area"></v-icon>
                            <span class="text-caption mt-1">{{
                              selectedItem.files.length
                            }}</span>
                          </div>
                          <div
                            class="d-flex justify-end mt-n8"
                            v-if="selectedItem.files.length > 2"
                          ></div>
                        </v-img>
                      </div>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
              <!-- IMAGES DIALOG -->
              <v-dialog
                v-model="AllImages"
                transition="dialog-bottom-transition"
                fullscreen
              >
                <v-card>
                  <v-toolbar>
                    <v-icon
                      class="ml-2"
                      size="small"
                      color="error"
                      icon="mdi-arrow-left"
                      @click="AllImages = false"
                    ></v-icon>

                    <v-spacer></v-spacer>

                    <v-btn
                      color="success"
                      variant="text"
                      prepend-icon="mdi-heart-outline"
                      class=""
                      @click="close"
                    >
                      Favorite
                    </v-btn>
                    <!-- <v-btn
                      color="error"
                      variant="text"
                      prepend-icon="mdi-share-variant-outline"
                      :class="this.$vuetify.display.mobile ? '' : 'ml-2'"
                      @click="close"
                    >
                      Share
                    </v-btn> -->
                  </v-toolbar>
                  <v-card-text class="ma-4">
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                        v-for="(image, index) in selectedItem.files"
                        :key="index"
                      >
                        <div
                          :class="
                            this.$vuetify.display.mobile
                              ? 'rounded-lg mx-n9'
                              : 'rounded-lg'
                          "
                        >
                          <v-img
                            height="350"
                            :src="`data:${image?.mime_type};base64,${image?.base64_data}`"
                            cover
                            class="rounded-lg"
                            aspect-ratio="16/9"
                          >
                          </v-img>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-toolbar
                    v-if="!this.$vuetify.display.mobile"
                    :elevation="1"
                    color="white"
                    class="position-sticky bottom-0 justify-center align-center rounded-lg mx-auto ma-2 pa-2"
                    style="width: 44%"
                  >
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-row>
                          <v-col cols="6">
                            <v-img
                              height="80"
                              src="@/assets/profile.png"
                              class="rounded-lg"
                            ></v-img>
                          </v-col>
                          <v-col cols="6">
                            <div class="mt-8 ml-n3">
                              <span class="font-weight-black">
                                {{ selectedItem.user?.fullName }}</span
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="12" md="8" class="m-7">
                        <v-btn
                          color="primary"
                          variant="tonal"
                          prepend-icon="mdi-email"
                          class="ml-4 mt-6"
                          @click="emailDialogOn"
                        >
                          Email
                        </v-btn>
                        <v-btn
                          color="error"
                          variant="tonal"
                          prepend-icon="mdi-phone"
                          class="ml-2 mt-6"
                          @click="callDialog = true"
                        >
                          Call
                        </v-btn>
                        <v-btn
                          color="success"
                          variant="tonal"
                          prepend-icon="mdi-whatsapp"
                          class="ml-2 mt-6"
                          :href="whatsappUrl"
                          target="_blank"
                        >
                          Whatsapp
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                </v-card>
              </v-dialog>
              <!-- END -->
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="8" class="ml-n5">
                    <v-card
                      :class="
                        this.$vuetify.display.mobile ? 'ml-n6 mr-n12 mt-n7' : ''
                      "
                      elevation="0"
                    >
                      <template v-slot:title>
                        <div class="d-flex">
                          <span class="font-weight-black">{{
                            convertToCurrency(selectedItem.price)
                          }}</span>
                        </div>
                        <div class="text-caption">
                          <span v-if="selectedItem.activeRelation.bedrooms > 0">
                            <v-icon class="ml-n1" icon="mdi-bed-queen"></v-icon>
                            <span class="text-black ml-1">{{
                              selectedItem.activeRelation.bedrooms +
                              " " +
                              "Bed(s)"
                            }}</span>
                          </span>

                          <span
                            v-if="selectedItem.activeRelation.bathrooms > 0"
                          >
                            <v-icon
                              :class="
                                selectedItem.activeRelation.bedrooms
                                  ? 'ml-6'
                                  : 'ml-n1'
                              "
                              icon="mdi-shower"
                            ></v-icon>
                            <span class="text-black ml-1">{{
                              selectedItem.activeRelation.bathrooms +
                              " " +
                              "Bathroom(s)"
                            }}</span>
                          </span>
                          <span v-if="selectedItem.activeRelation.size > 0">
                            <v-icon
                              :class="
                                selectedItem.activeRelation.bedrooms &&
                                selectedItem.activeRelation.bedrooms
                                  ? 'ml-6'
                                  : 'ml-n1'
                              "
                              icon="mdi-ruler-square"
                            ></v-icon>
                            <span class="text-black ml-1">{{
                              selectedItem.activeRelation.size + " " + "sqft"
                            }}</span>
                          </span>
                        </div>
                      </template>

                      <template v-slot:append>
                        <v-row v-if="!this.$vuetify.display.mobile">
                          <v-col cols="12" md="6">
                            <v-btn
                              color="blue"
                              variant="text"
                              prepend-icon="mdi-message"
                              :class="
                                this.$vuetify.display.mobile ? '' : 'ml-n4'
                              "
                              @click="chatUser"
                            >
                              Chat
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-btn
                              color="success"
                              variant="text"
                              prepend-icon="mdi-heart-outline"
                              :class="
                                this.$vuetify.display.mobile ? '' : 'ml-n4'
                              "
                              @click="close"
                            >
                              Favorite
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:subtitle>
                        <div class="mt-2">
                          <v-icon
                            color="black"
                            class="ml-n1"
                            icon="mdi-map-marker"
                          ></v-icon>
                          <span class="text-black"
                            >{{ selectedItem.city.title }},
                            {{ selectedItem.city.country.name }}</span
                          >
                        </div>
                      </template>
                      <v-divider></v-divider>
                      <v-card-text class="pt-4">
                        <div>
                          <span class="font-weight-bold text-h6">
                            {{ selectedItem.title }}
                          </span>

                          <v-container class="ml-n3 mt-2 mb-6">
                            <!-- The Small Screen1 -->
                            <v-row
                              class="ml-1"
                              v-if="this.$vuetify.display.mobile"
                            >
                              <!-- Content on the left -->
                              <v-row>
                                <v-col cols="6">
                                  <div>Type</div>
                                </v-col>
                                <v-col cols="6">
                                  <div
                                    class="font-weight-black"
                                    v-if="selectedItem.category.name"
                                  >
                                    {{ selectedItem.category.name }}
                                  </div>
                                  <div v-else class="font-weight-black">
                                    {{ "N/A" }}
                                  </div>
                                </v-col>
                                <v-col cols="6">
                                  <div>Property Age</div>
                                </v-col>
                                <v-col cols="6">
                                  <div
                                    v-if="selectedItem.activeRelation.age"
                                    class="font-weight-black"
                                  >
                                    {{ selectedItem.activeRelation.age }}
                                  </div>
                                  <div v-else class="font-weight-black">
                                    {{ "N/A" }}
                                  </div>
                                </v-col>
                                <v-col cols="6">
                                  <div>Updated</div>
                                </v-col>
                                <v-col cols="6">
                                  <div
                                    v-if="
                                      selectedItem.activeRelation.updated_at
                                    "
                                    class="font-weight-black"
                                  >
                                    {{
                                      formatDate(
                                        selectedItem.activeRelation.updated_at
                                      )
                                    }}
                                  </div></v-col
                                >
                              </v-row>

                              <v-row>
                                <v-col cols="6">
                                  <div>Purpose</div>
                                </v-col>
                                <v-col cols="6">
                                  <div
                                    class="font-weight-black"
                                    v-if="
                                      selectedItem.activeRelation.propertyType
                                    "
                                  >
                                    {{
                                      selectedItem.activeRelation.propertyType
                                    }}
                                  </div>
                                  <div v-else class="font-weight-black">
                                    {{ "N/A" }}
                                  </div>
                                </v-col>
                                <v-col cols="6">
                                  <div>Furnished</div>
                                </v-col>
                                <v-col cols="6">
                                  <div class="font-weight-black">
                                    {{
                                      selectedItem.activeRelation.isFurnished
                                        ? "Furnished"
                                        : "Unfurnished"
                                    }}
                                  </div></v-col
                                >
                              </v-row>
                            </v-row>
                            <!-- The Big Screen2 -->
                            <v-row class="text-caption" v-else>
                              <v-col>
                                <!-- Content on the left -->
                                <v-row>
                                  <v-col cols="6">
                                    <div>Type</div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div
                                      class="font-weight-black"
                                      v-if="selectedItem.category.name"
                                    >
                                      {{ selectedItem.category.name }}
                                    </div>
                                    <div v-else class="font-weight-black">
                                      {{ "N/A" }}
                                    </div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div>Property Age</div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div
                                      v-if="selectedItem.activeRelation.age"
                                      class="font-weight-black"
                                    >
                                      {{ selectedItem.activeRelation.age }}
                                    </div>
                                    <div v-else class="font-weight-black">
                                      {{ "N/A" }}
                                    </div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div>Updated</div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div
                                      v-if="
                                        selectedItem.activeRelation.updated_at
                                      "
                                      class="font-weight-black"
                                    >
                                      {{
                                        formatDate(
                                          selectedItem.activeRelation.updated_at
                                        )
                                      }}
                                    </div></v-col
                                  >
                                </v-row>
                              </v-col>

                              <!-- Vertical Divider -->
                              <v-divider
                                :thickness="2"
                                color="black"
                                vertical
                                class="mx-auto"
                              ></v-divider>

                              <v-col>
                                <!-- Content on the right -->
                                <v-row>
                                  <v-col cols="6">
                                    <div>Purpose</div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div
                                      class="font-weight-black"
                                      v-if="
                                        selectedItem.activeRelation.propertyType
                                      "
                                    >
                                      {{
                                        selectedItem.activeRelation.propertyType
                                      }}
                                    </div>
                                    <div v-else class="font-weight-black">
                                      {{ "N/A" }}
                                    </div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div>Furnished</div>
                                  </v-col>
                                  <v-col cols="6">
                                    <div class="font-weight-black">
                                      {{
                                        selectedItem.activeRelation.isFurnished
                                          ? "Furnished"
                                          : "Unfurnished"
                                      }}
                                    </div></v-col
                                  >
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                        <v-list class="ml-n2">
                          <v-list-item v-if="selectedItem.activeRelation.brand">
                            <span>Brand:</span>
                            {{ selectedItem.activeRelation.brand }}
                          </v-list-item>
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.age &&
                              selectedItem.activeRelation.age !== 'null'
                            "
                          >
                            <span>Age (Years):</span>
                            {{ selectedItem.activeRelation.age }}
                          </v-list-item>
                          <v-list-item
                            v-if="selectedItem.activeRelation.condition"
                          >
                            <span>Condition:</span>
                            {{ selectedItem.activeRelation.condition }}
                          </v-list-item>
                          <v-list-item v-if="selectedItem.activeRelation.usage">
                            <span>Usage:</span>
                            {{ selectedItem.activeRelation.usage }}
                          </v-list-item>
                          <v-list-item
                            v-if="selectedItem.activeRelation.warranty"
                          >
                            <span>Warranty(years):</span>
                            {{ selectedItem.activeRelation.warranty }}
                          </v-list-item>
                          <v-list-item
                            v-if="selectedItem.activeRelation.occupancyStatus"
                          >
                            <span>Occupancy Status:</span>
                            {{ selectedItem.activeRelation.occupancyStatus }}
                          </v-list-item>

                          <v-list-item
                            v-if="selectedItem.activeRelation.isFurnished"
                          >
                            <span>Furnishing:</span>
                            {{
                              selectedItem.activeRelation.isFurnished
                                ? "Furnished"
                                : "Unfurnished"
                            }}
                          </v-list-item>

                          <!-- Bathrooms -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.bathrooms !== 'null'
                            "
                          >
                            <span>Bathrooms:</span>
                            {{ selectedItem.activeRelation.bathrooms || "0" }}
                          </v-list-item>

                          <!-- Bedrooms -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.bedrooms !== 'null'
                            "
                          >
                            <span>Bedrooms:</span>
                            {{ selectedItem.activeRelation.bedrooms || "0" }}
                          </v-list-item>

                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.maintenanceFee !==
                              'null'
                            "
                          >
                            <span>Maintenance Fee:</span>
                            {{
                              selectedItem.activeRelation.maintenanceFee || "0"
                            }}
                          </v-list-item>

                          <!-- Minimum Contract Period -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation
                                .minimumContractPeriod !== 'null'
                            "
                          >
                            <span>Minimum Contract Period (years):</span>
                            {{
                              selectedItem.activeRelation
                                .minimumContractPeriod || "0"
                            }}
                          </v-list-item>

                          <!-- Notice Period -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.noticePeriod !==
                              'null'
                            "
                          >
                            <span>Notice Period (months):</span>
                            {{
                              selectedItem.activeRelation.noticePeriod || "0"
                            }}
                          </v-list-item>

                          <!-- Price Per Year -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.pricePerYear !==
                              'null'
                            "
                          >
                            <span>Price Per Year:</span>
                            {{
                              selectedItem.activeRelation.pricePerYear || "0"
                            }}
                          </v-list-item>

                          <!-- Rent Paid -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.rentPaid"
                          >
                            <span>Rent Paid:</span>
                            {{ selectedItem.activeRelation.rentPaid }}
                          </v-list-item>

                          <!-- Size -->
                          <v-list-item v-if="selectedItem.activeRelation.size">
                            <span>Size:</span>
                            {{ selectedItem.activeRelation.size }}
                          </v-list-item>

                          <!-- Make/Model -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.makeModel"
                          >
                            <span>Make/Model:</span>
                            {{ selectedItem.activeRelation.makeModel }}
                          </v-list-item>

                          <!-- Body Type -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.bodyType"
                          >
                            <span>Body Type:</span>
                            {{ selectedItem.activeRelation.bodyType }}
                          </v-list-item>

                          <!-- Is Insured -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.isInsured"
                          >
                            <span>Insurance:</span>
                            {{ selectedItem.activeRelation.isInsured }}
                          </v-list-item>

                          <!-- Kilometers -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.kilometers"
                          >
                            <span>Mileage (Kilometers):</span>
                            {{ selectedItem.activeRelation.kilometers }}
                          </v-list-item>

                          <!-- Price -->
                          <v-list-item v-if="selectedItem.activeRelation.price">
                            <span>Price:</span>
                            {{ selectedItem.activeRelation.price }}
                          </v-list-item>

                          <!-- Specs -->
                          <v-list-item v-if="selectedItem.activeRelation.specs">
                            <span>Specs:</span>
                            {{ selectedItem.activeRelation.specs }}
                          </v-list-item>

                          <!-- Year -->
                          <v-list-item v-if="selectedItem.activeRelation.year">
                            <span>Year:</span>
                            {{ selectedItem.activeRelation.year }}
                          </v-list-item>

                          <!-- Trim -->
                          <v-list-item v-if="selectedItem.activeRelation.trim">
                            <span>Trim:</span>
                            {{ selectedItem.activeRelation.trim }}
                          </v-list-item>

                          <!-- Phone Number -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.phoneNumber"
                          >
                            <span>Phone Number:</span>
                            {{ selectedItem.activeRelation.phoneNumber }}
                          </v-list-item>

                          <!-- Last Posted (updated_at) -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.description"
                          >
                            <span class="">
                              {{ selectedItem.activeRelation.description }}
                            </span>
                          </v-list-item>

                          <!-- Last Posted (updated_at) -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.updated_at"
                          >
                            <strong>Posted:</strong>
                            {{
                              formatDate(selectedItem.activeRelation.updated_at)
                            }}
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>

                    <!-- -------AMENITIES------- -->
                    <v-divider></v-divider>
                    <v-card
                      :class="
                        this.$vuetify.display.mobile
                          ? 'mr-n9 mt-2 ml-n2'
                          : 'mt-2'
                      "
                      v-if="selectedItem.category.slug === 'property'"
                      elevation="0"
                    >
                      <template v-slot:title>
                        <span class="font-weight-black">Amenities</span>
                      </template>

                      <v-card-text class="pt-4">
                        <v-row>
                          <v-col
                            v-if="selectedItem.activeRelation.maidsRoom"
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('maidsRoom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Maids Room
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.studyRoom &&
                              selectedItem.activeRelation.studyRoom != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('studyRoom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Study Room
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.centralACHeating &&
                              selectedItem.activeRelation.centralACHeating != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('centralACHeating')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Central AC Heating
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.conciergeService &&
                              selectedItem.activeRelation.conciergeService != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('conciergeService')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Concierge Service
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.balcony &&
                              selectedItem.activeRelation.balcony != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('balcony')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Balcony
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.privateGarden &&
                              selectedItem.activeRelation.privateGarden != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('privateGarden')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Private Garden
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.privatePool &&
                              selectedItem.activeRelation.privatePool != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('privatePool')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Private Pool
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.privateGym &&
                              selectedItem.activeRelation.privateGym != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('privateGym')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Private Gym
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.privateJacuzzi &&
                              selectedItem.activeRelation.privateJacuzzi != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('privateJacuzzi')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Private Jacuzzi
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.sharedPool &&
                              selectedItem.activeRelation.sharedPool != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('sharedPool')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Shared Pool
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.sharedSpa &&
                              selectedItem.activeRelation.sharedSpa != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('sharedSpa')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Shared Spa
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.sharedGym &&
                              selectedItem.activeRelation.sharedGym != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('sharedGym')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Shared Gym
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.security &&
                              selectedItem.activeRelation.security != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('security')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Security
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.maidService &&
                              selectedItem.activeRelation.maidService != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('maidService')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Maid Service
                              </div>
                            </v-card>
                          </v-col>
                          <!-- second butch -->

                          <v-col
                            v-if="
                              selectedItem.activeRelation.coveredParking &&
                              selectedItem.activeRelation.coveredParking != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('coveredParking')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Covered Parking
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.builtInWardrobes &&
                              selectedItem.activeRelation.builtInWardrobes != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('builtInWardrobes')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Built-in Wardrobes
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.walkInCloset &&
                              selectedItem.activeRelation.walkInCloset != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('walkInCloset')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Walk-in Closet
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.builtInKitchenAppliances &&
                              selectedItem.activeRelation.builtInKitchenAppliances !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('builtInKitchenAppliances')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Built-in Kitchen Appliances
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.viewOfWater &&
                              selectedItem.activeRelation.viewOfWater != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('viewOfWater')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                View of Water
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.viewOfLandmark &&
                              selectedItem.activeRelation.viewOfLandmark != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('viewOfLandmark')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                View of Landmark
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.petsAllowed &&
                              selectedItem.activeRelation.petsAllowed != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('petsAllowed')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Pets Allowed
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.doubleGlazedWindows &&
                              selectedItem.activeRelation.doubleGlazedWindows !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('doubleGlazedWindows')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Double Glazed Windows
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.dayCareCenter &&
                              selectedItem.activeRelation.dayCareCenter != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('dayCareCenter')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Day Care Center
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.electricityBackup &&
                              selectedItem.activeRelation.electricityBackup !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('electricityBackup')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Electricity Backup
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.firstAidMedicalCenter &&
                              selectedItem.activeRelation.firstAidMedicalCenter !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('firstAidMedicalCenter')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                First Aid Medical Center
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.serviceElevators &&
                              selectedItem.activeRelation.serviceElevators != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('serviceElevators')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Service Elevators
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.prayerRoom &&
                              selectedItem.activeRelation.prayerRoom != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('prayerRoom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Prayer Room
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.laundryRoom &&
                              selectedItem.activeRelation.laundryRoom != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('laundryRoom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Laundry Room
                              </div>
                            </v-card>
                          </v-col>
                          <!-- third butch -->

                          <v-col
                            v-if="
                              selectedItem.activeRelation.broadbandInternet &&
                              selectedItem.activeRelation.broadbandInternet !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('broadbandInternet')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Broadband Internet
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.satelliteCableTV &&
                              selectedItem.activeRelation.satelliteCableTV != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('satelliteCableTV')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Satellite Cable TV
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.businessCenter &&
                              selectedItem.activeRelation.businessCenter != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('businessCenter')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Business Center
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.intercom &&
                              selectedItem.activeRelation.intercom != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('intercom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Intercom
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.atmFacility &&
                              selectedItem.activeRelation.atmFacility != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('atmFacility')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                ATM Facility
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.kidsPlayArea &&
                              selectedItem.activeRelation.kidsPlayArea != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('kidsPlayArea')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Kids Play Area
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.receptionWaitingRoom &&
                              selectedItem.activeRelation.receptionWaitingRoom !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('receptionWaitingRoom')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Reception Waiting Room
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.maintenanceStaff &&
                              selectedItem.activeRelation.maintenanceStaff != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('maintenanceStaff')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Maintenance Staff
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.cctvSecurity &&
                              selectedItem.activeRelation.cctvSecurity != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('cctvSecurity')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                CCTV Security
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.cafeteriaOrCanteen &&
                              selectedItem.activeRelation.cafeteriaOrCanteen !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('cafeteriaOrCanteen')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Cafeteria or Canteen
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.sharedKitchen &&
                              selectedItem.activeRelation.sharedKitchen != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('sharedKitchen')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Shared Kitchen
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.facilitiesForDisabled &&
                              selectedItem.activeRelation.facilitiesForDisabled !=
                                'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('facilitiesForDisabled')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Facilities for Disabled
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.storageAreas &&
                              selectedItem.activeRelation.storageAreas != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('storageAreas')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Storage Areas
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            v-if="
                              selectedItem.activeRelation.cleaningServices &&
                              selectedItem.activeRelation.cleaningServices != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('cleaningServices')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Cleaning Services
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.barbequeArea &&
                              selectedItem.activeRelation.barbequeArea != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('barbequeArea')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Barbeque Area
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.lobbyInBuilding &&
                              selectedItem.activeRelation.lobbyInBuilding != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('lobbyInBuilding')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Lobby in Building
                              </div>
                            </v-card>
                          </v-col>

                          <v-col
                            v-if="
                              selectedItem.activeRelation.wasteDisposal &&
                              selectedItem.activeRelation.wasteDisposal != 'false'
                            "
                            cols="6"
                            md="3"
                          >
                            <v-card
                              align="center"
                              class="rounded-lg pa-5 bg-grey-lighten-4"
                              elevation="0"
                            >
                              <v-img
                                :src="getImage('wasteDisposal')"
                                class="rounded-lg"
                                max-width="35"
                                max-height="35"
                              ></v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                Waste Disposal
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <!-- -------MAP VIEW ------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile
                          ? 'mr-n9 mt-2 mb-3 ml-n2'
                          : 'mt-2 mb-3'
                      "
                      elevation="0"
                    >
                      <template v-slot:title>
                        <div class="font-weight-black">Map View</div>
                        <div class="text-black text-caption">
                          {{ selectedItem.city.title }},
                          {{ selectedItem.city.country.name }}
                        </div>
                      </template>
                      <v-card-text class="pt-4">
                        <GoogleMap :cities="selectedItem.city"></GoogleMap>
                      </v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                    <div class="mt-3 text-center">
                      <v-dialog
                        v-model="isActive.value"
                        width="auto"
                        scrollable
                      >
                        <template v-slot:activator="{ props: activatorProps }">
                          <v-btn
                            class="text-capitalize"
                            prepend-icon="mdi-flag"
                            variant="text"
                            v-bind="activatorProps"
                          >
                            Report This Ad
                          </v-btn>
                        </template>
                        <template v-slot:default="{ isActive }">
                          <v-card
                            prepend-icon=" mdi-flag"
                            title="Report Ad"
                            width="320"
                          >
                            <v-divider class="mt-3 mb-n4"></v-divider>

                            <v-card-text class="px-4"> </v-card-text>
                            <v-radio-group
                              v-model="editedItem.description"
                              messages="Select a reason"
                            >
                              <v-radio
                                label="Spam"
                                value="Spam"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Fraud"
                                value="Fraud"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Miscategorized"
                                value="Miscategorised"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Repetitive Listing"
                                value="Repetitive Listing"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Copyright Infridgement"
                                value="Copyright Infridgement"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Not Available"
                                value="Not Available"
                                color="error"
                              ></v-radio>

                              <v-radio
                                label="Incorrect Pricing"
                                value="Incorrect Pricing"
                                color="error"
                              ></v-radio>
                            </v-radio-group>
                            <v-divider></v-divider>

                            <v-card-actions>
                              <v-btn
                                icon="mdi-close"
                                color="error"
                                @click="isActive.value = false"
                              ></v-btn>

                              <v-spacer></v-spacer>

                              <v-btn
                                color="error"
                                text="Report Ad"
                                variant="outlined"
                                @click="reportListing"
                              ></v-btn>
                            </v-card-actions> </v-card
                        ></template>
                      </v-dialog>
                    </div>

                    <!-- -------SIMILAR ADS------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile ? 'mr-n9 mt-6' : 'mt-6'
                      "
                      v-if="selectedItem.activeRelation.description"
                      elevation="0"
                    >
                      <template v-slot:title>
                        <span class="font-weight-black">SIMILAR ADS</span>
                      </template>
                      <v-divider></v-divider>
                      <v-card-text class="pt-4 my-3">
                        <div>
                          <v-card
                            elevation="0"
                            class="ml-1"
                            width="
                                  180
                                "
                            :loading="loading"
                          >
                            <v-img
                              class="bg-grey-lighten-2"
                              height="160"
                              :src="`data:${selectedItem.files[0]?.mime_type};base64,${selectedItem.files[0]?.base64_data}`"
                              cover
                            ></v-img>
                            <v-card-title
                              elevation="0"
                              :class="
                                this.$vuetify.display.mobile
                                  ? 'text-left ml-n3'
                                  : 'text-left  ml-n3'
                              "
                            >
                              <div
                                class="text-error text-button font-weight-bold"
                              >
                                {{ "USD" + " " + selectedItem.price }}
                              </div>
                              <div
                                class="text-black mt-n2 font-weight-bold text-caption"
                              >
                                {{ selectedItem.title }}
                              </div>
                              <div class="text-grey text-caption text-truncate">
                                {{ selectedItem.description }}
                              </div>
                            </v-card-title>
                          </v-card>
                        </div></v-card-text
                      ></v-card
                    >

                    <!-- ------- ADS NEAR ------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile
                          ? 'mr-n9 mt-2 mb-3'
                          : 'mt-2 mb-3 ml-2'
                      "
                      elevation="0"
                    >
                      <template v-slot:title>
                        <div class="text-caption font-weight-black">
                          Ads near
                          {{ selectedItem.city.title }},
                          {{ selectedItem.city.country.name }}
                        </div>
                      </template>
                      <v-card-text class="pt-4">
                        <v-list-item
                          v-for="(item, i) in cities"
                          :key="i"
                          :value="item"
                          :to="item.route"
                          color="black"
                          class="ml-n4 mt-n2"
                        >
                          <v-list-item-title class="text-caption text-blue">
                            {{ item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- ---- USER PROFILE ---- -->
                  <v-col
                    cols="12"
                    md="4"
                    :class="
                      this.$vuetify.display.mobile ? 'sticky-btn mb-5' : ''
                    "
                  >
                    <v-card
                      :height="this.$vuetify.display.mobile ? '100' : ''"
                      :class="
                        this.$vuetify.display.mobile ? 'mx-n8 mb-n12 pt-2' : ''
                      "
                      rounded="lg"
                      :elevation="this.$vuetify.display.mobile ? '0' : '1'"
                    >
                      <v-card-text v-if="$vuetify.display.mdAndUp">
                        <v-row>
                          <v-col cols="4">
                            <div class="rounded-lg">
                              <v-img
                                height="80"
                                src="@/assets/profile.png"
                                cover
                                class="rounded-lg"
                              >
                              </v-img>
                            </div>
                          </v-col>
                          <v-col cols="8">
                            <div class="mt-3 text-center">
                              <span class="font-weight-black">
                                {{ selectedItem.user?.fullName }}</span
                              >
                              <div class="">
                                <span class="font-weight-black text-caption">
                                  EMAIL:
                                </span>
                                {{ selectedItem.user?.email }}
                              </div>
                              <div class="">
                                <span class="font-weight-black text-caption">
                                  Tel:
                                </span>
                                {{ selectedItem.user?.phoneNumber }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions
                        :class="
                          this.$vuetify.display.mobile
                            ? 'd-flex justify-center'
                            : ''
                        "
                      >
                        <v-btn
                          color="primary"
                          variant="tonal"
                          prepend-icon="mdi-email"
                          class="ml-1"
                          @click="emailDialogOn"
                        >
                          Email
                        </v-btn>
                        <v-btn
                          color="error"
                          variant="tonal"
                          prepend-icon="mdi-phone"
                          class=" "
                          @click="callDialog = true"
                        >
                          Call
                        </v-btn>

                        <v-btn
                          color="success"
                          variant="tonal"
                          prepend-icon="mdi-whatsapp"
                          class="ml-n1"
                          :href="whatsappUrl"
                          target="_blank"
                        >
                          Whatsapp
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    <div>
                      <!-- Call Dialog -->
                      <v-dialog v-model="callDialog" width="auto">
                        <v-card
                          :width="$vuetify.display.mobile ? '320' : '500'"
                          class="rounded-lg pa-2"
                        >
                          <v-card-title>
                            <v-toolbar class="bg-white">
                              <v-row class="text-caption">
                                <v-col
                                  cols="4"
                                  :class="
                                    $vuetify.display.mobile ? 'ml-n1' : ''
                                  "
                                >
                                  <div class="rounded-lg">
                                    <v-img
                                      height="80"
                                      src="@/assets/profile.png"
                                      cover
                                      class="rounded-lg"
                                    >
                                    </v-img>
                                  </div>
                                </v-col>
                                <v-col cols="8">
                                  <div class="mt-5 ml-n3">
                                    <span class="font-weight-black">
                                      {{ selectedItem.user?.fullName }}</span
                                    >
                                    <div class="">
                                      <span
                                        class="font-weight-black text-caption"
                                      >
                                        EMAIL:
                                      </span>
                                      {{ selectedItem.user?.email }}
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-spacer></v-spacer>
                              <v-btn
                                v-if="$vuetify.display.mdAndUp"
                                color="error"
                                icon="mdi-close"
                                variant="plain"
                                @click="callDialog = false"
                              >
                              </v-btn>
                            </v-toolbar>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-text class="text-center mt-3">
                            Don't forget to mention the property title
                            <span class="text-error">{{
                              selectedItem.title
                            }}</span>
                            when you call.
                          </v-card-text>
                          <template v-slot:actions>
                            <v-btn
                              class="ms-auto"
                              variant="tonal"
                              color="error"
                              block
                              @click="dialog = false"
                            >
                              {{ selectedItem.user?.phoneNumber }}</v-btn
                            >
                          </template>
                        </v-card>
                      </v-dialog>

                      <!-- Email Dialog -->
                      <v-dialog v-model="emailDialog" width="auto">
                        <v-card
                          :width="$vuetify.display.mobile ? '320' : '600'"
                          class="rounded-lg"
                        >
                          <v-card-title>
                            <v-toolbar class="bg-white">
                              Inquire About {{ selectedItem.title }}
                              <v-spacer></v-spacer>
                              <v-btn
                                color="error"
                                icon="mdi-close"
                                variant="plain"
                                @click="emailDialog = false"
                              >
                              </v-btn>
                            </v-toolbar>
                          </v-card-title>
                          <v-card-subtitle class="mt-n3">
                            <v-row class="text-caption">
                              <v-col cols="4">
                                <div class="rounded-lg">
                                  <v-img
                                    height="80"
                                    :src="`data:${selectedItem.files[0]?.mime_type};base64,${selectedItem.files[0]?.base64_data}`"
                                    cover
                                    class="rounded-lg ml-1"
                                  >
                                  </v-img>
                                </div>
                              </v-col>
                              <v-col cols="8" class="mt-4">
                                <div class="d-flex">
                                  <span class="font-weight-black text-error"
                                    >{{ "USD" + " " }}
                                    {{ selectedItem.price }}</span
                                  >
                                </div>
                                <span class="text-black"
                                  >{{ selectedItem.city.title }},
                                  {{ selectedItem.city.country.name }}</span
                                ></v-col
                              ></v-row
                            >
                          </v-card-subtitle>
                          <v-card-text class="mx-n1">
                            <!-- form here -->
                            <v-form ref="form" v-model="isFormValid">
                              <v-textarea
                                v-model="editedItem.message"
                                auto-grow
                                label="Message"
                                class="mt-1"
                                variant="outlined"
                                :rules="[(v) => !!v || 'message is required']"
                              ></v-textarea>
                              <v-text-field
                                v-model="editedItem.fullName"
                                label="Full Name"
                                class="mt-1"
                                variant="outlined"
                                :rules="[(v) => !!v || 'full name is required']"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.email"
                                label="Email"
                                class="mt-1"
                                type="email"
                                variant="outlined"
                                :rules="[(v) => !!v || 'email is required']"
                              ></v-text-field>
                              <v-text-field
                                v-model="editedItem.phoneNumber"
                                label="Phone Number"
                                class="mt-1"
                                variant="outlined"
                                :rules="[
                                  (v) => !!v || 'phone number is required',
                                ]"
                              ></v-text-field>
                            </v-form>
                          </v-card-text>
                          <template v-slot:actions>
                            <v-col cols="12" class="mt-n4">
                              <div class="text-center">
                                <v-btn
                                  class="ms-auto"
                                  variant="tonal"
                                  color="grey"
                                  block
                                  :disabled="!isFormValid"
                                  @click="inquireListing"
                                >
                                  <span class="text-capitalize"
                                    >Inquire Now</span
                                  ></v-btn
                                >
                                <div class="mt-6 text-caption">
                                  <span class="text-grey">
                                    By clicking on 'Inquire Now', I agree to the
                                    <v-btn
                                      @click="terms"
                                      variant="text"
                                      class="text-none ml-n1"
                                      size="x-small"
                                      color="blue"
                                      >Terms and Conditions</v-btn
                                    >
                                    <span class="ml-n1"> and </span>
                                    <v-btn
                                      @click="policies"
                                      variant="text"
                                      class="text-none ml-n2"
                                      size="x-small"
                                      color="blue"
                                      >Privacy Policies</v-btn
                                    >
                                  </span>
                                </div>
                              </div>
                            </v-col>
                          </template>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
      <!-- end of main content-->

      <div v-if="!this.$vuetify.display.mobile">
        <FooterComponent></FooterComponent>
      </div>
    </v-app>
  </div>
</template>
<script>
import FooterComponent from "@/components/footer/FooterComponent.vue";
import GoogleMap from "@/components/GoogleMapComponent.vue";
import CityService from "@/services/cities.services";
import CountryService from "@/services/country.services.js";
import CategoryService from "@/services/category.services";
import ListingService from "../services/listing.service.js";
import ChatService from "@/services/chat.service.js";
import DisputeService from "@/services/disputes.service.js";
import moment from "moment";

export default {
  name: "HomeComponent",
  components: {
    FooterComponent,
    GoogleMap,
  },

  data: () => ({
    value: 1,
    AllImages: false,
    emailDialog: false,
    whatsappUrl: process.env.VUE_APP_WHATSAPP_URL,
    callDialog: false,
    isFormValid: false,
    country: {},
    cities: [],
    isActive: { value: false },
    editedItem: {
      message: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      listingId: "",
      description: "",
    },

    defaultItem: {
      message: "",
      fullName: "",
      phoneNumber: "",
      email: "",
      listingId: "",
      description: "",
    },

    ecosystem: [
      {
        text: "Notification",
        route: "/notification",
        icon: "mdi-bell-outline",
      },
      {
        text: "My Searches",
        route: "/my-search",
        icon: "mdi-feature-search-outline",
      },
      {
        text: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        text: "My Ads",
        route: "/my-ads",
        icon: "mdi-view-list-outline",
      },
    ],
    bottomNav: [
      {
        name: "Home",
        route: "/",
        icon: "mdi-home",
      },
      {
        name: "Search",
        route: "",
        icon: "mdi-magnify",
        menu: "search",
      },
      {
        name: "Post an Ad",
        route: "/place-ad",
        icon: "mdi-plus-circle-outline",
      },
      {
        name: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        name: "menu",
        route: "",
        icon: "mdi-menu",
        menu: "menu",
      },
    ],

    fetureCategories: [
      {
        icon: "motors",
        title: "Motors",
        subcategories: [
          { title: "Cars" },
          { title: "Motorcycles" },
          { title: "Auto Accessories & Parts" },
          { title: "Heavy Vehicles" },
        ],
        allInText: "All in Motors",
      },
      {
        icon: "property-for-rent",
        title: "Property for Rent",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "Rooms For Rent" },
          { title: "Monthly Short Term" },
        ],
        allInText: "All in Property for Rent",
      },
      {
        icon: "property-for-sale",
        title: "Property for Sale",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "New Projects" },
          { title: "Off-Plan" },
        ],
        allInText: "All in Property for Sale",
      },
      {
        icon: "classified",
        title: "Classifieds",
        subcategories: [
          { title: "Electronics" },
          { title: "Computers & Networking" },
          { title: "Clothing & Accessories" },
          { title: "Jewelry & Watches" },
        ],
        allInText: "All in Classifieds",
      },
      {
        icon: "jobs",
        title: "Jobs",
        subcategories: [
          { title: "Accounting / Finance" },
          { title: "Engineering" },
          { title: "Sales / Business Development" },
          { title: "Secretarial / Front Office" },
        ],
        allInText: "All in Jobs",
      },
      {
        icon: "community",
        title: "Community",
        subcategories: [
          { title: "Freelancers" },
          { title: "Home Maintenance" },
          { title: "Other Services" },
          { title: "Tutors & Classes" },
        ],
        allInText: "All in Community",
      },
      {
        icon: "business-industrial",
        title: "Business & Industrial",
        subcategories: [
          { title: "Businesses for Sale" },
          { title: "Construction" },
          { title: "Food & Beverage" },
          { title: "Industrial Supplies" },
        ],
        allInText: "All in Business & Industrial",
      },
      {
        icon: "home-appliances",
        title: "Home Appliances",
        subcategories: [
          { title: "Large Appliances / White Goods" },
          { title: "Small Kitchen Appliances" },
          { title: "Outdoor Appliances" },
          { title: "Small Bathroom Appliances" },
        ],
        allInText: "All in Home Appliances",
      },
      {
        icon: "furniture-home-garden",
        title: "Furniture, Home & Garden",
        subcategories: [
          { title: "Furniture" },
          { title: "Home Accessories" },
          { title: "Garden & Outdoor" },
          { title: "Lighting & Fans" },
        ],
        allInText: "All in Furniture, Home & Garden",
      },
      {
        icon: "mobile-phones-pdas",
        title: "Mobile Phones & Tablets",
        subcategories: [
          { title: "Mobile Phones" },
          { title: "Mobile Phone & Tablet Accessories" },
          { title: "Tablets" },
          { title: "Other Mobile Phones & Tablets" },
        ],
        allInText: "All in Mobile Phones & Tablets",
      },
    ],

    chatDetail: {
      vendor_id: "",
      customer_id: "",
    },

    menuActive: false,
    searchActive: false,
    categories: {},
    listings: [],
    chat: [],
    searchQuery: "",
  }),
  computed: {
    loggedInUser() {
      return this.$store.state.auth.user;
    },

    selectedItem() {
      return this.$store.getters["category/getSelectedItem"];
    },

    user() {
      return this.loggedInUser || {};
    },

    groupedListings() {
      return this.listings.reduce((grouped, listing) => {
        const categorySlug = listing.category.slug;
        if (!grouped[categorySlug]) {
          grouped[categorySlug] = [];
        }
        grouped[categorySlug].push(listing);
        return grouped;
      }, {});
    },

    filteredListings() {
      return this.listings.filter((listing) => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          listing.title.toLowerCase().includes(searchQueryLower) ||
          listing.description.toLowerCase().includes(searchQueryLower)
        );
      });
    },
  },

  created() {
    this.getDefaultCountry();
    this.getCategories();
    this.getlistings();
  },

  methods: {
    getImage(amenityKey) {
      try {
        return require(`@/assets/${amenityKey}.svg`);
      } catch (error) {
        return require("@/assets/balcony.svg");
      }
    },

    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    getCategoryName(slug) {
      const category = this.categories.find((cat) => cat.slug === slug);
      return category ? category.title : "Unknown Category";
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    backToSearch() {
      this.$router.push("/");
    },

    formatDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY");
    },

    convertToCurrency(value) {
      let number = value;

      if (typeof value === "string") {
        number = parseFloat(value.replace(/[^0-9.-]+/g, ""));
      }

      if (!isNaN(number)) {
        return "USD" + " " + number.toLocaleString("en-US");
      } else {
        return "Invalid number";
      }
    },

    emailDialogOn() {
      if (this.loggedInUser) {
        this.editedItem.fullName =
          this.loggedInUser.fullName ?? this.loggedInUser.fullName;
        this.editedItem.email =
          this.loggedInUser.email ?? this.loggedInUser.email;
        this.editedItem.phoneNumber =
          this.loggedInUser.phoneNumber ?? this.loggedInUser.phoneNumber;
      }
      this.editedItem.message = "Hi, i would like ...";
      this.emailDialog = true;
    },

    getCategories() {
      return CategoryService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    toList() {
      // push to list
      const category = this.selectedItem.category;
      this.$store.dispatch("filter/setSelectedAttributes", category);
      this.$router.push({
        name: "list",
        query: {
          category: category.title,
        },
      });
    },

    formatKey(key) {
      // Convert camelCase to a more human-readable format
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },

    getlistings() {
      return ListingService.getApprovedOpenListing().then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            // console.log("the list", this.listings);
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    inquireListing() {
      this.editedItem.listingId = this.selectedItem.id;
      return ListingService.inquireListing(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.editedItem = this.defaultItem;
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    reportListing() {
      this.editedItem.listingId = this.selectedItem.id;
      return DisputeService.store(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.$store.dispatch("alert/success", response.data.message);
            this.editedItem = this.defaultItem;
            this.loading = false;
            this.isActive.value = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    chatUser() {
      if (this.loggedInUser) {
        this.chatDetail.vendor_id = this.loggedInUser._id;
        this.chatDetail.customer_id = this.selectedItem.user._id;
        return ChatService.getOrCreateChat(this.chatDetail).then(
          (response) => {
            if (response.status == 200) {
              this.chat = response.data.data;
              this.$store.dispatch("chat/setCreatedChat", this.chat);
              this.$router.push("/chats");
              this.$store.dispatch("alert/success", response.data.message);
              console.log("the list", this.chat);
              this.loading = false;
            }
          },
          (error) => {
            this.chat = [];
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        );
      } else {
        this.$router.push("/");
        this.$store.dispatch("alert/error", "Login to chat");
      }
    },

    getDefaultCountry() {
      return CountryService.defaultCountry().then(
        (response) => {
          if (response.status == 200) {
            this.country = response.data.data;
            this.getcities(this.country.id);
            this.loading = false;
          } else {
            this.country = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.country = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    // get cities
    getcities(countryId) {
      return CityService.getCountryCities(countryId).then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data.slice(0, 6);
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    async getlistingFile(gridfs_id) {
      return ListingService.getListingFile(gridfs_id).then(
        (response) => {
          if (response.status == 200) {
            const mimeType = response.headers["content-type"]; // Get the MIME type from the response
            const base64Image = this.convertToBase64(response.data, mimeType); // Convert binary data to Base64 with dynamic MIME
            return base64Image;

            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.console.error);
            return null;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          return null;
        }
      );
    },

    convertToBase64(binaryData, mimeType) {
      // Ensure binary data is a Uint8Array
      const base64String = btoa(
        new Uint8Array(binaryData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return `data:${mimeType};base64,${base64String}`;
    },

    loginOrSignup() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push("/");
    },

    showAllImages() {
      this.AllImages = true;
    },

    routeTo(route) {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || route);
    },

    placeAd() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/place-ad");
    },

    toggle(item) {
      if ((!item.route && item.menu === "menu") || "search") {
        item.menu === "menu"
          ? (this.menuActive = !this.menuActive)
          : (this.searchActive = !this.searchActive);
      }
    },

    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    base64ToFile(base64String, fileType, fileName) {
      const base64Data = base64String.replace(/^data:.+;base64,/, "");
      const byteCharacters = atob(base64Data);
      const byteArray = [...byteCharacters].map((char) => char.charCodeAt(0));
      const blob = new Blob([new Uint8Array(byteArray)], { type: fileType });
      const file = new File([blob], fileName, { type: fileType });
      console.log(file);
      return file;
    },
  },
};
</script>
<style scoped>
.v-input__icon--append .v-icon {
  color: purple;
}

.custom-card {
  background-color: rgba(0, 0, 0, 0.6);
}

.hover-effect {
  background-color: transparent !important;
  transition: color 0.3s ease;
}

.hover-effect:hover {
  background-color: transparent !important;
  color: blue !important;
}

.v-list-item-title {
  color: inherit;
}

.sticky-btn {
  position: sticky;
  bottom: 0;
  z-index: 10;
}
</style>
