// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class ListingDisputesService {

    listingDisputes(id) {
        return axios.get(API_URL + 'listing-disputes/' + id, { headers: authHeader() })
    }

    store(resource) {
        return axios.post(API_URL + 'report-a-listing', resource, { headers: authHeader() })
    }

}

export default new ListingDisputesService()