// store/modules/search.module.js
const state = {
    newSearchKey: null,
    newSearchCategory: null,
  }
  
  const mutations = {
    setSearchKey(state, key) {
      state.newSearchKey = key;
    },

    setSearchCategory(state, category) {
      state.newSearchCategory = category;
    },
  }
  
  const actions = {
    updateSearchKey({ commit }, key) {
      commit('setSearchKey', key);
      console.log('setSearchKey', key);
    },

    updateSearchCategory({ commit }, category) {
      commit('setSearchCategory', category);
      console.log('setSearchCategory', category);
    },
  }
  
  const getters = {
    getNewSearchKey(state) {
      return state.newSearchKey;
    },
    
    getNewSearchCategory(state) {
      return state.newSearchCategory;
    },
  }
  
  export const search = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
  