<template>
  <v-row class="mr-n5">
    <v-col class="bg-grey-lighten-3" cols="12">
      <v-card color="grey-lighten-3" class="ma-2" elevation="0">
        <v-card-title class="font-weight-black text-h5">{{
          "Listings"
        }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3" v-for="(category, i) in categories" :key="i">
              <v-card
                class="pa-2"
                elevation="0"
                color="white"
                rounded="lg"
                height="120"
              >
                <v-card-title class="text-caption font-weight-bold">{{
                  category.title
                }}</v-card-title>
                <v-card-text>
                  <div
                    class="d-flex align-center ml-1 text-h6 font-weight-black"
                  >
                    {{ category.stat }}
                  </div>
                </v-card-text>
                <v-card-action>
                  <div class="text-caption ml-2">
                    {{ "+10"
                    }}<span class="text-grey">{{ " last month" }}</span>
                  </div>
                </v-card-action>
              </v-card>
            </v-col>

            <v-col cols="12" md="3">
              <v-card
                class="pa-2"
                elevation="0"
                color="white"
                rounded="lg"
                height="120"
              >
                <v-card-title class="text-caption font-weight-bold">{{
                  "Total"
                }}</v-card-title>
                <v-card-text>
                  <div class="d-flex align-center text-h6 font-weight-black">
                    {{ "2666" }}
                  </div>
                </v-card-text>
                <v-card-action>
                  <div class="text-caption ml-2">
                    {{ "+70"
                    }}<span class="text-grey">{{ " last month" }}</span>
                  </div>
                </v-card-action>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <!-- table -->
      <v-data-table
        :headers="headers"
        :items="users"
        :sort-by="[{ key: '#', order: 'asc' }]"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span
                  class="text-caption text-uppercase font-weight-bold text--darken-1 justify-center text-black"
                  >{{ formTitle }}</span
                >
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="isValid" @submit.prevent="save">
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="editedItem.fullName"
                          label="fullName"
                          color="black"
                          :rules="rules.fullName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="email"
                          color="black"
                          :rules="rules.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-autocomplete
                          v-model="editedItem.roleId"
                          label="Select Role"
                          :items="roles"
                          item-value="id"
                          item-text="title"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-autocomplete
                          v-model="editedItem.active"
                          label="Select Status"
                          :items="status"
                          item-value="value"
                          item-text="title"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions class="mr-7 mt-n7 mb-4">
                <v-spacer></v-spacer>
                <v-btn color="error" variant="outlined" @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="black"
                  variant="outlined"
                  :disabled="!isValid"
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="mx-10">
              <v-card-title class="text-subtitle-2 text-center text-black">
                You sure you want to delete this user?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" variant="outlined" @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="black"
                  variant="outlined"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.id`]="{ index }">
          <span class="text-pink">{{ index + 1 }}</span>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          {{ item.active ? "Active" : "Deactivated" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="black"
            class="ml-3"
            icon="mdi-dots-vertical"
            @click="editItem(item)"
          ></v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="black" variant="outlined" @click="initialize">
            Reset
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import UsersService from "../services/user.service.js";
import RolesService from "../services/role.services.js";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    isValid: false,
    rules: {
      fullName: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a full name.";
        },
      ],
      email: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a email.";
        },
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Name", value: "fullName", key: "fullName" },
      { title: "Email", value: "email", key: "email" },
      { title: "Phone Number", value: "phoneNumber", key: "phoneNumber" },
      { title: "Role", value: "role.title", key: "role.title" },
      { title: "Status", value: "active", key: "active" },
      { title: "Actions", key: "actions", sortable: false },
    ],

    categories: [
      {
        title: "Motors",
        avatar: "mdi-account-group",
        stat: "256",
      },
      {
        title: "Properties",
        avatar: "mdi-google-ads",
        stat: "235",
      },
      {
        title: "Classified",
        avatar: "mdi-alert-octagon",
        stat: "435",
      },
      {
        title: "Jobs",
        avatar: "mdi-alert-octagon",
        stat: "435",
      },
      {
        title: "Community",
        avatar: "mdi-alert-octagon",
        stat: "435",
      },
      {
        title: "Funiture & Garden",
        avatar: "mdi-alert-octagon",
        stat: "435",
      },
      {
        title: "Mobile & Tables",
        avatar: "mdi-alert-octagon",
        stat: "435",
      },
    ],

    status: [
      {
        title: "Active",
        value: 1,
      },
      {
        title: "Deactivated",
        value: 0,
      },
    ],

    editedIndex: -1,
    users: [],
    roles: [],
    editedItem: {
      fullName: "",
      email: "",
      active: 1,
      description: "",
    },
    defaultItem: {
      fullName: "",
      email: "",
      active: 0,
      description: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getSubcategories();
    this.getRoles();
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getSubcategories() {
      return UsersService.index().then(
        (response) => {
          if (response.status == 200) {
            this.users = response.data.data;
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.users = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getRoles() {
      return RolesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.roles = response.data.data;
            this.loading = false;
          } else {
            this.roles = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.roles = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      UsersService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.users.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.users[this.editedIndex], this.editedItem);
          UsersService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                this.loading = false;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          UsersService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.users.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
