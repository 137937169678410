import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { alert } from './alert.module'
import { notification } from './notification.module';
import { auth } from './auth.module';
import { category } from './category.module';
import { filter } from './filter.module';
import { chat } from './chat.module'
import { map } from './map.module'
import { search } from './search.module'

export default createStore({
  plugins: [createPersistedState()],
  state: {
    isAuthenticated: false,
    inactivityTimeout: null,
  },

  mutations: {
    login(state) {
      state.isAuthenticated = true;
    },

    logout(state) {
      state.isAuthenticated = false;
    },

    setInactivityTimeout(state, timeout) {
      state.inactivityTimeout = timeout;
    },
    
    clearUser(state) {
      state.isAuthenticated = false;
    },

  },

  actions: {
    startInactivityTimeout({ commit, dispatch }) {
      const timeout = setTimeout(() => {
        dispatch('logout');
        commit('setInactivityTimeout', null);
      }, 1 * 60 * 1000);

      commit('setInactivityTimeout', timeout);
    },

    resetInactivityTimeout({ state, dispatch }) {
      if (state.inactivityTimeout) {
        clearTimeout(state.inactivityTimeout);
      }
      dispatch('startInactivityTimeout');
    },
    
    clearUserData({ commit }) {
      localStorage.removeItem('user');
      commit('clearUser');
    },
  },

  // Modules
  modules: {
    auth,
    alert,
    notification,
    category,
    filter,
    chat,
    map,
    search,
  }
})