<template>
  <div>
    <!-- App Bar with Logo -->
    <v-app-bar class="text-center" :elevation="1">
      <v-img
        :src="require('@/assets/suuq.logo.svg')"
        alt="SUUQ Logo"
        class="ml-8"
        @click="logoRoute"
      />
    </v-app-bar>

    <!-- Chat List Section -->
    <v-row class="main-content">
      <v-col cols="12" class="mx-auto">
        <v-card
          :width="$vuetify.display.mobile ? '' : 700"
          elevation="0"
          class="mx-auto"
        >
          <v-card-title class="mb-1">
            <div class="font-weight-black">
              Chats
              <div class="text-caption text-grey mt-n1">
                Continue with your conversation
              </div>
            </div>
          </v-card-title>

          <v-card-text class="mt-n4">
            <div v-if="userChats.length > 0">
              <v-list class="ml-n4">
                <v-list-item v-for="(chat, index) in userChats" :key="index">
                  <v-card
                    rounded="20"
                    class="mt-1 pa-2 mb-1 bg-grey-lighten-4 rounded-lg"
                    elevation="1"
                    @click="showChat(chat)"
                  >
                    <v-card-title class="d-flex">
                      <div class="mt-n1">
                        <v-avatar
                          color="error"
                          icon="mdi-account"
                          variant="outlined"
                        ></v-avatar>
                      </div>
                      <div class="mt-2 text-caption font-weight-bold ml-6">
                        {{ getChatName(chat) }}
                      </div>
                      <v-spacer></v-spacer>
                      <div
                        v-if="chat.unread_messages_count > 0"
                        class="mt-n2 mr-3"
                      >
                        <v-badge color="success" dot></v-badge>
                      </div>
                      <div class="mt-2 mr-1 text-blue text-caption">
                        {{ formatDate(chat.created_at) }}
                      </div>
                      <div class="mt-n3">
                        <v-avatar
                          @click.stop="deleteChat(chat)"
                          class="mt-3 ml-2"
                          color="error"
                          variant="outlined"
                          size="small"
                        >
                          <v-icon
                            class=""
                            icon="mdi-delete"
                            color="error"
                            size="x-small"
                          ></v-icon>
                        </v-avatar>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-list-item>
              </v-list>
            </div>
            <div v-else class="text-center text-error text-caption">
              No chats available
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Chat Dialog Section -->
      <v-col>
        <v-dialog
          v-model="chatDialog"
          transition="dialog-bottom-transition"
          fullscreen
        >
          <v-card class="bg-grey-lighten-4">
            <!-- Dialog Toolbar -->
            <v-toolbar>
              <v-btn
                variant="outlined"
                size="small"
                color="blue"
                icon="mdi-close"
                @click="chatDialog = false"
              ></v-btn>

              <v-toolbar-title
                class="text-blue text-uppercase font-weight-bold text--darken-1"
              >
                {{ selectedChat.name }}
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-toolbar-items>
                <!-- here -->
                <v-btn
                  color="blue"
                  variant="text"
                  append-icon="mdi-send"
                  @click="sendMessage"
                >
                  Send
                </v-btn>
                <v-btn
                  text="Close"
                  variant="text"
                  append-icon="mdi-close"
                  color="blue"
                  @click="chatDialog = false"
                ></v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <!-- Chat Message Display Section -->
            <v-card-text>
              <v-row class="chat-content">
                <v-col
                  v-for="(message, index) in sortedMessages"
                  :key="index"
                  cols="12"
                  class="mt-n4"
                >
                  <div
                    :class="[
                      'd-flex',
                      message.sender_id === loggedInUser._id
                        ? 'justify-end'
                        : 'justify-start',
                      'my-2',
                    ]"
                  >
                    <v-avatar
                      :color="
                        message.sender_id === loggedInUser._id
                          ? 'error'
                          : 'blue'
                      "
                      size="36"
                    >
                      <span class="white--text">
                        {{
                          message.sender_id === loggedInUser._id
                            ? "Y"
                            : message.from
                        }}
                      </span>
                    </v-avatar>
                    <v-card
                      :class="[
                        message.sender_id === loggedInUser._id
                          ? 'bg-blue'
                          : 'bg-grey-lighten-4',
                      ]"
                      class="ma-2"
                    >
                      <!-- Display message timestamp below the message -->
                      <v-card-title>
                        <div
                          v-if="message.created_at"
                          class="mt-1 text-caption text-grey"
                        >
                          {{ formatDate(message.created_at) }}
                        </div>
                      </v-card-title>
                      <v-card-text class="white--text">{{
                        message.message
                      }}</v-card-text>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- Message Input Section -->
            <v-card-actions>
              <v-row>
                <v-col cols="12" md="10">
                  <v-textarea
                    v-model="editedItem.message"
                    label="Type a message..."
                    :class="[
                      $vuetify.display.mobile ? 'mt-n1 mb-n8' : 'mt-n1 mb-n4',
                    ]"
                    variant="outlined"
                    :rules="[(v) => !!v]"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="2" class="mx-auto">
                  <v-btn
                    color="blue"
                    variant="outlined"
                    size="x-large"
                    :class="$vuetify.display.mobile ? '' : 'mt-10'"
                    append-icon="mdi-send"
                    block
                    @click="sendMessage"
                  >
                    Send
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { subscribeToChat } from "@/services/chat.pusher.js";
import ChatService from "@/services/chat.service.js";
import { now } from "moment";

export default {
  data() {
    return {
      chatDialog: false,
      DateNow: now(),
      userChats: [], // Default to empty array
      chatMessages: [], // Default to empty array
      editedItem: {
        message: "",
      },
      selectedChat: {
        id: "",
        name: "",
      },
    };
  },

  computed: {
    sortedMessages() {
      return this.chatMessages.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA - dateB;
      });
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    createdChat() {
      return this.$store.state.chat.createdChat;
    },
  },

  mounted() {
    if (this.loggedInUser && this.loggedInUser._id) {
      subscribeToChat(this.loggedInUser._id, (data) => {
        if (Array.isArray(data)) {
          this.chatMessages = [...data, ...this.chatMessages];
        } else {
          const newMessage = {
            sender_id: data.sender_id,
            from: data.sender_id === this.loggedInUser._id ? "You" : "Other",
            message: data.message,
            created_at: data.created_at,
          };
          this.chatMessages.push(newMessage.message);
        }
      });
    }
  },

  created() {
    if (this.loggedInUser) {
      this.getUserChats();
    }
    if (this.createdChat) {
      this.showChat(this.createdChat);
      this.$store.dispatch("chat/clearCreatedChat");
    }
    console.log("createdChat", this.createdChat);
  },

  methods: {
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = String(d.getFullYear()).slice(-2);
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },

    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },

    getChatName(chat) {
      const { vendor, customer } = chat.participants;
      if (vendor._id === this.loggedInUser._id) return customer.fullName;
      return vendor.fullName;
    },

    getUserChats() {
      ChatService.getUserChats(this.loggedInUser._id).then((response) => {
        if (response.status === 200) {
          this.userChats = response.data.data || [];
          this.updateUnreadMessagesCount();
        } else {
          this.userChats = [];
        }
      });
    },

    updateUnreadMessagesCount() {
      const totalUnread = this.userChats.reduce(
        (sum, chat) => sum + (chat.unread_messages_count || 0),
        0
      );
      this.$store.dispatch("chat/setUnreadMessageCount", totalUnread);
    },

    getChatMessages(chat) {
      ChatService.getChatMessages(chat.id).then((response) => {
        if (response.status === 200) {
          this.chatMessages = response.data.data || [];
          this.chatDialog = true;
        }
      });
    },

    MarkChatMessagesRead(chat) {
      ChatService.MarkChatMessagesRead(chat.id).then((response) => {
        if (response.status === 200) {
          this.$store.dispatch("alert/success", response.data.message);
        }
      });
    },

    showChat(chat) {
      console.log("chat", chat);
      this.selectedChat.id = chat.id;
      this.selectedChat.participants = chat.participants;
      this.selectedChat.name = this.getChatName(chat);
      this.getChatMessages(chat);
      this.MarkChatMessagesRead(chat);
    },

    sendMessage() {
      this.editedItem.chat_id = this.selectedChat.id;
      this.editedItem.sender_id = this.loggedInUser._id;
      this.editedItem.receiver_id =
        this.selectedChat.participants.customer._id === this.loggedInUser._id
          ? this.selectedChat.participants.vendor._id
          : this.selectedChat.participants.customer._id;
      if (!this.editedItem.message.trim()) return;
      return ChatService.sendMessages(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            const message = {
              sender_id: this.loggedInUser._id,
              from: "You", // This will display as "You" for logged-in user
              message: response.data.data.message,
              created_at: response.data.data.created_at,
            };
            this.chatMessages.push(message);
            this.$store.dispatch("alert/success", response.data.message);
            this.editedItem.message = "";
          }
        },
        (error) => {
          this.roles = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    deleteChat(chat) {
      ChatService.delete(chat).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.userChats.splice(this.editedIndex, 1);
            window.location.reload();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>

<style scoped>
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-content {
  overflow-y: auto;
  max-height: 67vh;
  padding: 10px;
}

.v-card-text {
  font-size: 14px;
  line-height: 1.4;
}

.v-avatar {
  margin-right: 8px;
}

.mt-1 {
  margin-top: 8px;
}
</style>
