<template>
  <v-app id="inspire">
    <!-- Left Drawer -->
    <v-navigation-drawer
      style="position: fixed"
      class="text-caption"
      elevation="0"
      clipped
      rounded="sm"
      v-model="drawer"
      color="black"
    >
      <!-- logo section -->
      <v-card color="grey-lighten-3" class="d-flex align-center justify-center" height="60">
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          @click="logoRoute"
          cover
          max-width="100"
        />
      </v-card>

      <v-list class="ml-n3 pa-2 mx-3">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.route"
          :value="item.route"
          :base-color="item.color"
          rounded="xl"
          class="text-caption my-1 ml-5"
          :color="item.color"
          v-model="selectedItem"
          @click="toggleActive(item)"
        >
          <v-list-group class="mr-n10 pa-1" v-if="item.children" :value="item">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" rounded="lg" class="ml-n5 mr-n10">
                <template v-slot:prepend>
                  <div class="ma-1">
                    <v-icon
                      size="x-large"
                      color="white"
                      class="ml-5"
                      :icon="item.icon"
                    ></v-icon>
                  </div>
                </template>
                <template v-slot:title>
                  <span class="text-caption text-white ml-2 mt-1">{{
                    item.title
                  }}</span>
                </template>
              </v-list-item>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :value="child.title"
              :to="child.route"
              rounded="lg"
              class="ml-n9 text-caption"
            >
              <template v-slot:prepend>
                <v-avatar class="mr-n10 pr-2" :icon="child.icon"></v-avatar>
              </template>
              <template v-slot:title>
                <span
                  :color="child.color"
                  class="text-caption text-white ml-5 pr-1"
                  >{{ child.title }}</span
                >
              </template>
            </v-list-item>
          </v-list-group>
          <template v-slot:prepend>
            <div v-if="!item.children" class="ma-1">
              <v-icon
                size="x-large"
                class="ml-5 mr-n2"
                color="white"
                :icon="item.icon"
              ></v-icon>
            </div>
          </template>
          <v-list-item-title
            v-if="!item.children"
            class="text-caption text-white ml-4 mt-1"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <!-- update profile -->
      <v-dialog v-model="dialog" width="500">
        <v-form ref="form">
          <v-card title="Update Profile">
            <v-container class="mt-n4">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.fullName"
                    :rules="[rules.firstname]"
                    name="name"
                    label="Name"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col class="mt-n7" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.email"
                    :rules="[rules.email]"
                    name="email"
                    label="Email"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n7" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.phoneNumber"
                    :rules="[rules.phonenumber]"
                    name="phone"
                    label="Phone Number"
                    readonly
                    variant="outlined"
                    class="mt-2"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.oldPassword"
                    :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show1 ? 'text' : 'password'"
                    label="Current Password"
                    hint="At least 8 characters"
                    counter
                    variant="outlined"
                    @click:append-inner="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col class="mt-n5" cols="12" sm="12">
                  <v-text-field
                    v-model="editedItem.password"
                    :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.password]"
                    :type="show1 ? 'text' : 'password'"
                    label="New Password"
                    hint="At least 8 characters"
                    counter
                    required
                    variant="outlined"
                    @click:append-inner="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn variant="outlined" color="error" @click="close">
                Cancel
              </v-btn>
              <v-btn
                color="black"
                variant="outlined"
                @click="profileSave"
                :loading="loading"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <!--end of update profile -->
      <template v-slot:append>
        <v-card
          class="d-flex align-center justify-center mx-3 align-self-end mb-2 pb-1"
          height="70"
        >
          <v-row class="ma-2">
            <v-vol cols="4" class="mt-2 mr-3" @click="updateProfile">
              <v-avatar size="36px">
                <v-img
                  alt="Avatar"
                  src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                ></v-img> </v-avatar
            ></v-vol>
            <v-vol cols="4" class="mt-2 mr-7" @click="updateProfile">
              <v-vol cols="12">
                <strong v-if="loggedInUser">{{ loggedInUser.fullName }}</strong>
                <strong v-else class="">{{ "John Doe" }}</strong>
              </v-vol>
              <v-vol cols="12" class="d-flex align-end">
                <v-btn color="black" size="x-small"> {{ "Admin" }}</v-btn>
              </v-vol>
            </v-vol>
            <v-vol cols="4" class="mt-3">
              <v-avatar
                icon="mdi-logout-variant"
                size="30"
                @click="handleLogout"
              ></v-avatar>
            </v-vol>
          </v-row>
        </v-card>
      </template>
    </v-navigation-drawer>

    <!-- app bar -->
    <v-app-bar
      elevation="0"
      clipped-left
      style="position: fixed"
      class="text-white font-weight-bold"
      color="grey-lighten-3"
    >
      <v-spacer></v-spacer>
      <v-avatar class="ma-1" color="white">
        <v-app-bar-nav-icon
          color="black"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-avatar>
    </v-app-bar>
    <v-main class="">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
  

  <script>
import AuthService from "@/services/auth.service";
export default {
  data: () => ({
    drawer: null,
    selectedItem: null,
    dialog: false,
    show1: false,
    theUser: {},
    items: [
      {
        title: "Dashboard",
        icon: "mdi-widgets",
        route: "/admin",
        color: "primary",
      },
      {
        title: "Listings",
        icon: "mdi-contacts",
        route: "/listings",
        color: "primary",
      },
      {
        title: "Transactions",
        icon: "mdi-currency-usd",
        route: "/transactions",
        color: "primary",
      },
      {
        title: "Listing Costs",
        icon: "mdi-currency-usd-off",
        route: "/listing-cost",
        color: "primary",
      },
      {
        title: "Settings",
        icon: "mdi-cog-sync",
        route: "",
        color: "primary",
        children: [
          {
            title: "Users",
            icon: "mdi-account-multiple-plus",
            route: "/users",
            color: "primary",
          },
          {
            title: "Roles",
            icon: "mdi-account-tie",
            route: "/roles",
            color: "primary",
          },
          {
            title: "Permissions",
            icon: "mdi-access-point-check",
            route: "/permissions",
            color: "primary",
          },
          {
            title: "Categories",
            icon: "mdi-shape-plus",
            route: "/categories",
            color: "primary",
          },
          {
            title: "Subcategories",
            icon: "mdi-shape",
            route: "/sub-categories",
            color: "primary",
          },
          {
            title: "Groups",
            icon: "mdi-group",
            route: "/groups",
            color: "primary",
          },
          {
            title: "Countries",
            icon: "mdi-map-marker",
            route: "/countries",
            color: "primary",
          },
          {
            title: "Cities",
            icon: "mdi-city",
            route: "/cities",
            color: "primary",
          },
        ],
      },
    ],

    settings: [
      {
        title: "Org Types",
        icon: "mdi-contacts",
        route: "/organisation-types",
        color: "white",
      },
      {
        title: "Role Category",
        icon: "mdi-shape",
        route: "/role-category-types",
        color: "white",
      },
      {
        title: "Role Type",
        icon: "mdi-format-list-bulleted-type",
        route: "/role-type",
        color: "white",
      },
      {
        title: "Commission Cost",
        icon: "mdi-currency-usd",
        route: "/commission-cost",
        color: "white",
      },
      {
        title: "Reports",
        icon: "mdi-comment-text-multiple",
        route: "/reports",
        color: "white",
      },
    ],

    rules: {
      email: (value) => !!value || "email required",
      password: (value) => !!value || "new pasword required",
      firstname: (value) => !!value || "name required",
      lastname: (value) => !!value || "last name required",
      phonenumber: (value) => !!value || "phone number required",
    },

    loading: false,
    editedItem: {
      fullName: "",
      phoneNumber: "",
      email: "",
      password: "",
      oldassword: "",
    },
    defaultItem: {
      fulName: "",
      phoneNumber: "",
      email: "",
      password: "",
      oldPassword: "",
    },
  }),

  computed: {
    loggedInUser() {
      return this.$store.state.auth.user;
    },

    user() {
      return this.loggedInUser || {};
    },

    userCompany() {
      const storedCompany = localStorage.getItem("company");
      return storedCompany ? JSON.parse(storedCompany) : null;
    },

    initials() {
      if (this.user && this.user.firstname && this.user.lastname) {
        return `${this.user.firstname[0].toUpperCase()}${this.user.lastname[0].toUpperCase()}`;
      } else {
        return "";
      }
    },
  },

  created() {
    console.log("The users", this.user);
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    toggleActive(item) {
      if (item === this.selectedItem) {
        this.selectedItem = null;
      } else {
        this.selectedItem = item;
      }
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    updateProfile() {
      this.editedItem.firstname = this.user ? this.user.firstname : null;
      this.editedItem.lastname = this.user ? this.user.lastname : null;
      this.editedItem.phone =
        this.user.phoneNumbers && this.user.phoneNumbers.length > 0
          ? this.user.phoneNumbers[0].number
          : null;
      this.editedItem.email =
        this.user && this.user.email ? this.user.email : null;
      this.editedItem.password = "";
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.loading = false;
      });
    },

    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    async profileSave() {
      await this.validate();
      if (this.valid || this.valid.valid) {
        (this.loading = true),
          AuthService.resetPassword(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "profile updated Successfully"
                );
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", "Something Went Wrong!");
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              if (error.response.status == 401) {
                this.handleLogout();
              }
              this.close();
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
      }
    },
  },
};
</script>